.title {
    background-color: #FED000;
    border-radius: 5px;
    padding: 5px 10px 5px 20px;
    font-weight: 500;
}

.title .icon {
    width: 30px;
    position: absolute;
    top: 5px;
    left: -15px;
}

.link {
    text-decoration: underline;
}