.title {
  margin-top: 40px;
  text-align: start;
}

.smallText {
  font-size: 10px;
}

.verticallyAligned {
  display: flex;
  align-items: center;
}

.mcMenu {
  display: flex;
}

.mcMenuItem {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.burger {
  height: 50px;
}

.burger img {
  height: 100%;
}

.label {
  background-color: #d2d2d2;
  padding: 10px 15px;
  border-radius: 5px 5px 0px 0px;
}

.price {
  padding: 10px 15px 0px;
  border-radius: 0px 0px 5px 5px;
  border: 2px solid #d2d2d2;
}

.change {
  display: flex;
  align-items: baseline;
  padding: 10px 25px 0px;
}

.change svg {
  width: 15px;
  height: 15px;
}

.movement {
  display: flex;
  align-items: center;
}

.movementChart {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}

.movementReference {
  font-size: 0.8em;
}

.insight {
  font-size: 12px;
}

.insightTitle {
  background-color: #0b1435;
  color: #ffffff;
  font-size: 14px;
  padding: 4px 16px;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
}

.insightTitle svg {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -10px;
  left: -10px;
}
