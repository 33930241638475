.drawdown-table {
  .results-grid {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  div[role='grid'] {
    padding-bottom: 15px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 10px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
  }

  .results-grid .rdg-cell[role='columnheader']:not(:first-child)::before {
    content: '';
    border-left: 1px solid #ccc;
    position: absolute;
    height: 50%;
    left: 0;
    top: 25%;
  }
  .results-grid .rdg-cell[role='columnheader'] {
    text-transform: uppercase;
  }
}
