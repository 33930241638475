.quantity-fields {
  .quantity {
    display: flex;
  }

  .read-only-in-edit {
    background-color: #fff;
  }

  .read-only {
    background-color: #f2f3f8;
  }
}
