.pageHeading {
  padding: 0 24px 24px 24px;
  margin-bottom: 0 !important;
  background: white;
  font-weight: normal !important;
}

.contentCard {
  margin: 0 24px 24px 24px !important;
}

.dropdown {
  display: flex;
  background: white;
  justify-content: space-between;
  height: 30px;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  border: solid 1px #f2f2f2;
}

.dropdownTimeMode {
  margin-right: 20px;
  min-width: 120px;
  max-width: 34%;
}

.checkboxList :global(.ant-checkbox-group-item) {
  display: block;
}

.controls {
  display: flex;
}

.controls > span {
  flex-grow: 1;
}

.filterRow {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 10px 12px;
}

.filterItem {
  margin-right: 20px;
  min-width: 120px;
}

.filterItem:last-of-type {
  margin-right: 0;
}
