.Difot_title {
  font-size: 22px;
  display: flex;
  align-items: center;
  font-family: Poppins, sans-serif;
}

.Difot_title .Difot_line {
  width: 30px;
  height: 2px;
  background-color: rgba(252, 93, 54, 0.6);
  margin-right: 10px;
}

.Difot_label,
.Difot_chartLabel {
  text-transform: uppercase;
  color: #0b1435;
  opacity: 0.6;
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 16px;
}

.Difot_chartLabel {
  font-weight: 500;
}

.Difot_newBadge {
  border: 1px solid rgba(11, 20, 53, 0.7);
  color: #0b1435;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  display: inline-block;
  padding: 0px 6px;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 3px;
  position: relative;
  top: -1px;
  opacity: 0.6;
}

.Difot_table {
  width: 50%;
  margin-top: 20px;
}

.Difot_tableRow {
  display: flex;
  align-items: center;
  padding: 5px 10px 0 0;
}

.Difot_table .Difot_title {
  border-bottom: 1px solid #d2d2d2;
  text-transform: uppercase;
  font-size: 16px;
  color: #0b1435;
  opacity: 0.6;
  font-weight: 500;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.Difot_tableRow .Difot_label {
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000;
  text-transform: unset;
  opacity: 1;
  font-size: 14px;
  display: block;
  height: unset;
}

.Difot_score {
  margin-right: 10px;
  background-color: #f2f2f2;
  padding: 4px 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  text-align: center;
}

.Difot_difot :global(.base-chart .tick-x):after {
  content: '%';
  font-weight: normal;
}

.Difot_differenceArrow {
  display: inline-block;
  width: 20px;
  position: relative;
  top: 6px;
}
