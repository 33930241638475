.QualityAsl_totalSuppliers {
  font-size: 22px;
}

.QualityAsl_totalSuppliers span {
  font-size: 30px;
  font-weight: bolder;
}

.QualityAsl_numbers {
  opacity: 0.6;
  color: #0b1435;
}

.QualityAsl_divider {
  display: inline-block;
  margin: 0 6px;
}

.QualityAsl_newBadge {
  border: 1px solid rgba(11, 20, 53, 0.7);
  font-family: Poppins, sans-serif;
  font-weight: 500;
  display: inline-block;
  padding: 0 6px;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 3px;
  position: relative;
  top: -1px;
}

.QualityAsl_tables {
  display: flex;
}

.QualityAsl_table {
  width: 100%;
}

.QualityAsl_table:first-child {
  margin-right: 10px;
}

.QualityAsl_table:last-child {
  margin-left: 10px;
}

.QualityAsl_tableRow {
  /* display: flex; */
  align-items: center;
  padding: 3px 10px 3px 20px;
  position: relative;
}

.QualityAsl_tableRow[data-vertical='true'] {
  flex-direction: column;
}

.QualityAsl_tableRow[data-vertical='true']::before {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #0b1435;
  content: '';
  position: absolute;
  left: 0;
  top: 14px;
}

.QualityAsl_title {
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 8px;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 7px;
  font-weight: 500;
}

.QualityAsl_title span {
  color: #0b1435;
  opacity: 0.6;
}

.QualityAsl_progress {
  display: flex;
  align-items: center;
}

.QualityAsl_step {
  height: 3px;
  width: 5px;
  background-color: rgba(11, 20, 53, 0.1);
  margin: 0 1px;
}

.QualityAsl_step[data-completed='true'] {
  background-color: rgba(11, 20, 53, 1);
}

.QualityAsl_packerPlantColumn {
  width: calc(100% - 105px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.QualityAsl_ul {
  list-style-type: '- ';
}

.QualityAsl_readmoreText {
  text-decoration: underline;
}