.rejected-cartons {
  padding-bottom: var(--chakra-space-10);
  .rejected-cartons__header {
    margin: 10px 25px 0px 10px;
  }

  .rejected-cartons__hr {
    margin-left: 10px;
    margin-top: 14px;
  }

  .rejected-cartons__card {
    padding: 10px 0 0;
    background-color: #e0e0e0;
    border-left: 2px solid #c5c5c5;
    margin-bottom: 7px;

    .ant-card-body {
      padding-right: 50px;
    }
  }

  .ant-card-body {
    padding: 0 25px;
  }

  .rejected-cartons__text {
    padding-left: 25px;
  }

  .rejected-cartons__totals {
    padding: 0px 25px;
    display: flex;
  }

  .rejected-cartons__total {
    margin: 5px 40px 15px 0;
    display: flex;
  }
  .rejected-cartons__label {
    padding-right: 10px;
    font-weight: bold;
  }
}

// Don't wrap in parent selector, because popover is
// rendered at the top of the DOM
.rejected-cartons__popover-content {
  max-width: 300px;
}
