.confirmation-purchase-orders-grid {
  .confirmation-purchase-orders-grid__buy-sell-data {
    display: flex;
    flex-wrap: wrap;

    .confirmation-purchase-orders-grid__cell-data-0 {
      padding-right: 20px;
    }
  }
  .confirmation-purchase-orders-grid__cell-data-2 {
    padding-left: 20px;
    padding-right: 3px;
  }

  .confirmation-purchase-orders-grid__cell-data--centered {
    text-align: center;
    width: 70px;
  }

  .confirmation-purchase-orders-grid__footer {
    font-weight: 600;
  }

  .confirmation-purchase-orders-grid__po {
    font-weight: 500;
  }

  .react-grid-Container .react-grid-Cell__value {
    font-size: 14px;
  }
}
