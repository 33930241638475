.QualityNcrs_label {
  text-transform: uppercase;
  color: #0b1435;
  opacity: 0.6;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
}

.QualityNcrs_difference {
  color: rgba(11, 20, 53, 0.6);
}

.QualityNcrs_comparisonRank {
  color: rgba(11, 20, 53, 0.6);
}

.QualityNcrs_totalIncidents,
.QualityNcrs_incidentsPercent {
  font-family: Poppins, sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: #0b1435;
}

.QualityNcrs_totalIncidents span,
.QualityNcrs_incidentsPercent span {
  font-size: 30px;
  font-weight: bolder;
  color: black;
}

span.QualityNcrs_totalIncidents a,
span.QualityNcrs_totalIncidents a:hover,
span.QualityNcrs_totalIncidents a:focus {
  color: #0b1435;
}

.QualityNcrs_comparison {
  padding-top: 12px;
}

.QualityNcrs_byCategoryTitle,
.QualityNcrs_bottom5PackerPlantsTitle,
.QualityNcrs_summaryTitle {
  display: flex;
  justify-content: space-between;
}

.QualityNcrs_bottom5PackerPlantsTitle,
.QualityNcrs_summaryTitle {
  margin-top: 10px;
}

.QualityNcrs_byCategoryOptions {
  display: flex;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(210, 210, 210, 0.5);
}

.QualityNcrs_byCategoryOptions :global(.ant-checkbox-inner),
.QualityNcrs_byCategoryOptions :global(.ant-checkbox-wrapper:hover .ant-checkbox-inner),
.QualityNcrs_byCategoryOptions :global(.ant-checkbox:hover .ant-checkbox-inner),
.QualityNcrs_byCategoryOptions :global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
  border: solid 2px #fc5d36;
  border-radius: 2px;
}

.QualityNcrs_byCategoryOptions :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #fc5d36;
}

.QualityNcrs_byCategoryOptions :global(.ant-checkbox-checked .ant-checkbox-inner:after) {
  top: 5px;
  left: 2px;
}

.QualityNcrs_tableRow {
  display: flex;
  padding: 5px 0;
  height: 28px;
  align-items: center;
}

.QualityNcrs_tableRowSecondary {
  color: rgba(11, 20, 53, 0.6);
}

.QualityNcrs_tableRowValues {
  text-align: right;
  white-space: nowrap;
}

.QualityNcrs_summaryBlock {
  margin-bottom: 20px;
}

.QualityNcrs_claimDollars :global(.base-chart .tick-x):before {
  content: '$';
  font-weight: normal;
}

.QualityNcrs_claimDollars[data-packer='true'] :global(.base-chart .tick-x):before {
  content: '';
}

.QualityNcrs_claimDollars[data-packer='true'] :global(.base-chart .tick-x):after {
  content: '%';
  font-weight: normal;
}

.QualityNcrs_volumeSupplied :global(.base-chart .tick-x):after {
  content: '%';
  font-weight: normal;
}

.QualityNcrs_dollar {
  font-weight: normal;
}

.QualityNcrs_differenceArrow {
  display: inline-block;
  width: 20px;
  position: relative;
  top: 6px;
}

.QualityNcrs_viewAll,
.QualityNcrs_viewAll:hover {
  color: rgba(11, 20, 53, 0.6);
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.QualityNcrs_exportsCountryEntry {
  padding: 5px 20px;
  position: relative;
}

.QualityNcrs_exportsCountryEntry::before {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #0b1435;
  content: '';
  position: absolute;
  left: 0;
  top: 14px;
}

.QualityNcrs_legendBadge {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 2px;
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 2px,
    rgba(255, 255, 255, 0.5) 2px,
    rgba(255, 255, 255, 0.5) 4px
  );
}

.QualityNcrs_radio :global(.ant-radio-button-checked + span) {
  font-weight: bold;
}