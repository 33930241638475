.documents-queue {

  .filters {
    .chakra-menu__menu-button {
      height: 40px;
    }
  }

  .facets {
    padding: 20px 16px;
    border-radius: 6px;
    border: 1px solid #F3F3F3;
    background: #F7FAFC;
    /* Shadow/md */
    box-shadow: 0px 4px 6px -1px rgba(45, 55, 72, 0.10), 0px 2px 4px -1px rgba(45, 55, 72, 0.06);

    .facet {
      display: flex;
      margin: 6px 0;
      padding: 10px 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 4px;
      cursor: pointer;

      &.active {
        background: #EEF3F6;
        color: #3A44B0;
      }

      .label {
        flex: 1;
        margin: 0 6px;
        white-space: nowrap;
      }
    }
  }

  .results {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #EFEFEF;
    background: #FFF;
    /* Shadow/md */
    box-shadow: 0px 4px 6px -1px rgba(45, 55, 72, 0.10), 0px 2px 4px -1px rgba(45, 55, 72, 0.06);
    padding: 21px 20px;
    margin-left: 21px;

    table td {
      padding-left: 8px;

      button span {
        margin: 0;
      }
    }
  }

  .custom_pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;

    .pagination {
      margin-left: 0px;
      margin-right: 0px;
      justify-content: flex-end;
      font-family: HelveticaNeue;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.65);

      i {
        position: relative;
        bottom: 1px;
      }

      li {
        color: #5a5a5a;
        height: 32px;
        min-width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 4px;
        background-color: white;

        a {
          color: #5a5a5a;
        }
      }

      li.active {
        padding: 0px;
        border-width: 2px;
        border-color: var(--chakra-colors-actionSecondary-default);

        a {
          padding: 5px 12px;
          color: var(--chakra-colors-actionSecondary-default);
        }
      }

      li.previous,
      li.next {
        padding: 0px;

        a {
          padding: 15px;
        }
      }
    }
  }

  .pagination-stats {
    border: solid 1px #ececec;
    border-radius: 4px;
    padding: 10px;
    color: #808080;
    background-color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    margin-left: 21px;
  }
}


.documents-modal {
  .chakra-modal__content-container {
    overflow: hidden;
  }
  .chakra-modal__content {
    margin-top:0;
    margin-bottom: 0;
    min-height: 100%;
  }
}

.documents-queue-view-modal {
  .header {
    height: 61px;
    border-bottom: 1px solid #DCDFE3;

    .close-btn {
      margin-top: 6px;
    }
  }

  position: relative;

  .custom-header {
    height: 61px;
    border-bottom: 1px solid #DCDFE3;
    position: fixed;
    background: #fff;
    z-index: 99;
    width: 100%;
  }

  .main {
    flex: 1;
    background-color: #EFEFEF;
    width: 100%;
    height: calc(100vh - 80px);

    .attachment-pane {
      flex: 1;
      background-color: #EFEFEF;
      overflow: auto;
      //height: calc(100% - 32px);
      //min-width: 350px;
      height: calc(100% - 32px);
      width: 350px;
      min-width: 30%;
      margin-top: 16px;
      overflow-y: auto;

      .left-top {
        padding: 21px 30px;
        border-radius: 6px;
        border: 1px solid #EFEFEF;
        background-color: white;

        .email-subject {
          font-weight: bold;
        }

        .box-border {
          padding-bottom: 10px;
          margin-bottom: 10px;
        }

        .chakra-avatar {
          margin-right: 8px;
        }
      }

      .left-bottom {
        padding: 21px 30px;
        border-radius: 6px;
        border: 1px solid #EFEFEF;
        background-color: white;


        button span {
          margin: 0;
        }

        .pg-viewer-wrapper {
          border: 1px solid #EFEFEF;
          margin: 8px 0px;
        }
      }
      
      .pdf-attachment__filename {
        display: inline-flex;
        padding: 14px 8px;
        align-items: center;
        gap: 12px;
        border-radius: 4px 0px 0px 4px;
        // border: 1px solid #E2E2E2;
        background: #FFF;
        color: var(--Text-primary, rgba(0, 0, 0, 0.87));
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142%; /* 19.88px */
      }

      .pdf-attachment__btn {
        display: flex;
        width: 40px;
        height: 54px;
        padding: 24px 14px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 0px 4px 4px 0px;
        border: 1px solid #E2E2E2;
       // margin-left: 0px

       .chakra-button__icon {
        padding: 0 4px 13px 0;
       }
      }
      
    }

    .details-pane {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: white;
      min-width: 500px;
      border-bottom: 1px solid #EFEFEF;
      border-left: 1px solid #EFEFEF;
      margin: 16px 0 16px 30px;
      padding: 21px 30px;
      max-width: 70%;
      border-radius: 6px;
      height: calc(100% - 32px);
      overflow-y: auto;

      .top {
        padding: 25px 30px;
      }

      .bottom {
        padding: 23px 30px;
        display: flex;
        justify-content: space-between;

      }
    }

    .details-table {
      td, th {
        padding-inline-start: 1rem;
        padding-inline-end: 1rem;
      }
    }
  }
}