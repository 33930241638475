$c-header-text: #4a4a4a;
$c-primary-bg: #ec864f;
$c-primary-text: #fff;

.international-confirmation {
  .international-confirmation__header,
  h1 {
    font-family: Helvetica;
    font-size: 24px;
    color: $c-header-text;
  }

  .modal-header {
    padding: 32px 25px 16px 40px !important;
  }

  .modal-footer {
    border-top: 0px solid transparent;
  }

  .international-confirmation__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 50px;
  }

  .international-confirmation__button--close {
    padding-right: 20px;
  }

  .international-confirmation__button--link {
    font-family: Helvetica;
    font-size: 14px;
    color: $c-header-text;
    text-decoration: none;
    i {
      position: relative;
      top: 1px;
      padding-right: 1px;
    }
  }

  .international-confirmation__button--primary {
    background-color: $c-primary-bg;
    font-family: Helvetica;
    font-size: 14px;
    text-align: center;
    color: $c-primary-text;
    width: 120px;
    border-radius: 2px;
    border: 0px solid transparent;
    &.btn.btn-secondary:hover {
      background-color: $c-primary-bg;
    }
  }
}
