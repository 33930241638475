.grinder-schedule {
  .grinder-schedule__footer {
    text-align: right;
    padding-top: var(--chakra-space-6);
  }
}

.grinder-schedule-confirm-modal__content {
  text-align: center;

  .grinder-schedule-confirm-modal__content-table {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: var(--chakra-space-6);
  }

  .grinder-schedule-confirm-modal__error {
    width: 70%;
    max-width: var(--chakra-sizes-xl);
    max-width: 550px;
    padding-bottom: 15px;
    margin: auto;
  }
}
