.international-order-recurring-fields {
  display: flex;
  align-items: center;

  .international-order-recurring-fields__dates {
    display: inline-block;
    width: 393px;
    margin-bottom: 10px;
  }

  .international-order-recurring-fields__date {
    margin-right: 10px;
    width: 185px;
    display: inline-block;
  }

  .international-order-recurring-fields__day {
    display: inline-block;
  }

  .international-order-recurring-fields__day-content {
    display: flex;
    align-items: center;
    min-width: 60px;
    margin: 10px 0;

    .number-spinner-input {
      width: 88px;
      margin-top: 15px;
    }
  }

  .international-order-recurring-fields__day-content--wide {
    min-width: 190px;
  }

  input[type='checkbox'].error {
    outline: 1px solid red;
  }
}
