.buy-volumes-title {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 0;
    color: black;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: initial;
  }



  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
      content: '';
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
  }

  .ant-select-arrow {
    right: 5px;

    svg {
      fill: black;
    }
  }
}

.buy-volumes__sentence-selectors {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #828282;
    border-radius: 13px;
  }

  .ant-select-selection--single {
    height: auto;
  }

}
.chakra-stack.css-oz3v63{padding: 46px 47px;}
.chakra-table.css-5605sr th{
  font-family: 'Roboto',sans-serif;
  font-weight: var(--chakra-fontWeights-bold);
  text-transform: uppercase;
  letter-spacing: var(--chakra-letterSpacings-wider);
  text-align: start;
  background-color: #F7FAFC;
  -webkit-padding-start: var(--chakra-space-4);
  padding-inline-start: var(--chakra-space-4);
  -webkit-padding-end: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  padding-top: var(--chakra-space-1);
  padding-bottom: var(--chakra-space-1);
  line-height: var(--chakra-lineHeights-4);
  font-size: 12px;
  color: var(--chakra-colors-black);
  border-bottom: var(--chakra-borders-1px);
  border-color: var(--chakra-colors-gray-100);
  padding: 10px 8px;
  border-bottom-color: #B9B9B9;
  letter-spacing: inherit;
}