.ssr-list {
  font-family: Helvetica;

  .breadcrumbs .breadcrumbs__crumb--first {
    background-image: none;
  }
  .ssr-list__search {
    padding-bottom: 5px;
    width: 300px;
    float: left;
    .ssr-list__search-label {
      height: 17px;
      color: #000000;
      padding-bottom: 15px;
    }
    .ssr-list__search-input {
      width: 410px;
      height: 38px;
    }
  }

  .ssr-add-new-report {
    float: left;
    margin: 49px 0 0 15px;
  }

  .ant-card.ant-card-bordered {
    border: none !important;
  }

  .ssr-list__card {
    .ant-tabs-tab {
      height: 105px;
      width: 150px;
      border-radius: 6px;
      background-color: #ebebeb;
    }
    .ant-tabs-tab-btn {
      margin: 0 auto;
    }
    .ssr-list__card-categories-img img {
      width: 47px;
      height: 52px;
      margin-bottom: 5px;
    }
    .ant-tabs .ant-tabs-large-bar .ant-tabs-nav-container {
      font-size: 14px;
      color: #4a4a4a;
    }
    .ant-tabs-ink-bar {
      margin-top: 20px;
      height: 9px!important;
    }

    .ant-tabs-tab:hover {
      border-radius: 6px;
      border: solid 1px #6baaeb;
    }

    .ant-card-head .ant-tabs {
      margin-bottom: 8px;
    }
    .ant-card-head {
      border: none;
    }
  }

  .ssr-list__card-categories {
    text-align: center;
  }
  .ssr-list__card-content-table {
    table {
      border-collapse: collapse;
    }
    tr {
      display: block;
      margin-bottom: 5px;
    }
    .ssr-list__card-content-tr {
      min-width: 600px;
      height: 99px;
      border-radius: 4px;
      border: solid 1px #979797;
    }

    td {
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      color: #4a4a4a;
    }
    td:first-child {
      width: 328px;
      height: 88px;
      font-size: 14px;
      font-weight: bold;
      padding-left: 20px;
    }
    td:nth-child(2) {
      width: 695px;
      height: 85px;
      font-size: 14px;
      font-weight: normal;
    }

    button {
      width: 120px;
      height: 36px;
      border-radius: 2px;
      background-color: #2a90ff;
      margin-right: 55px;
    }
  }

  .select-field {
    max-width: 300px;
    .select-field__label {
      font-size: 16px;
      line-height: 1.18;
      margin-bottom: 12px;
      padding-left: 0px;
    }
    .Select-control {
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .Select-input > input {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .Select-input {
      height: 36px;
    }

    .Select-placeholder {
      line-height: 37px;
    }

    .Select-menu-outer {
      z-index: 10;
    }
  }
}
