@font-face {
    font-family: "CFCIcons";
    src: url("./CFCIcons.eot?c95d8fc5d52e203ce145660171fda2ca#iefix") format("embedded-opentype"),
url("./CFCIcons.woff2?c95d8fc5d52e203ce145660171fda2ca") format("woff2"),
url("./CFCIcons.woff?c95d8fc5d52e203ce145660171fda2ca") format("woff");
}

i.cfc-icon:before {
    font-family: CFCIcons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.cfc-icon.icon-com-settings:before {
    content: "\f101";
}
i.cfc-icon.icon-com-tick:before {
    content: "\f102";
}
i.cfc-icon.icon-cus-chicago-bean-cloudgate:before {
    content: "\f103";
}
i.cfc-icon.icon-cus-sydney-opera-house:before {
    content: "\f104";
}
i.cfc-icon.icon-doc-certificate:before {
    content: "\f105";
}
i.cfc-icon.icon-fin-bank:before {
    content: "\f106";
}
i.cfc-icon.icon-fin-notes:before {
    content: "\f107";
}
i.cfc-icon.icon-gen-home:before {
    content: "\f108";
}
i.cfc-icon.icon-gen-light-bulb:before {
    content: "\f109";
}
i.cfc-icon.icon-pre-bar-chart:before {
    content: "\f10a";
}
i.cfc-icon.icon-pre-chart-down:before {
    content: "\f10b";
}
i.cfc-icon.icon-pre-chart-up:before {
    content: "\f10c";
}
i.cfc-icon.icon-pre-line-graph:before {
    content: "\f10d";
}
i.cfc-icon.icon-pre-node-graph:before {
    content: "\f10e";
}
i.cfc-icon.icon-pre-pie-chart:before {
    content: "\f10f";
}
i.cfc-icon.icon-ui-bullets:before {
    content: "\f110";
}
i.cfc-icon.icon-use-user:before {
    content: "\f111";
}
i.cfc-icon.icon-wea-flake:before {
    content: "\f112";
}
