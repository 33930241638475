.document-types {

    .table-container {
        padding: 29px;
        border-radius: 6px;
        background: #FAFAFA;

        /* Shadow/md */
        box-shadow: 0px 4px 6px -1px rgba(45, 55, 72, 0.10), 0px 2px 4px -1px rgba(45, 55, 72, 0.06);

        .chakra-table {
            background-color: white;
        }

        table td {
            padding-left: 8px;

            button span {
                margin: 0;
            }

            &.actions {


                button {
                    padding: 4px;
                    margin: 0 2px;
                }

            }
        }

    }

}

.document-types-modal-content {
    header {
        border-radius: 8px 8px 0px 0px;
        background: #F7FAFC;
    }

    .email-document-fields {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .guide-box {
        max-width: 600px;
        padding: 1.5em;

        ul {
            margin: revert;
            padding: revert;
        }
    }

    .add-expression {
        padding: 0 8px;
    }

    footer {
        padding: 23px 30px;
        border-top: 1px solid #DCDFE3;
    }
}