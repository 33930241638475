$c-icon-gray: var(--chakra-colors-gray-300);
$c-icon-orange: var(--chakra-colors-secondary-900);

.international-order-line {
  background-color: var(--chakra-colors-white);
  padding-bottom: 25px;

  .international-order-line__header {
    padding-top: 15px;
    display: flex;
    align-items: center;

    .international-order-line__heading {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 700;
      color: var(--chakra-colors-blackAlpha-700);
      padding-left: 18px;
    }
  }

  .international-order-line__sub-heading {
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 700;
    color: var(--chakra-colors-blackAlpha-700);
    margin-top: 15px;
    padding-left: 25px;
    display: inline-block;
  }

  .international-order-line__row {
    margin-top: 15px;
    padding-left: 25px;
  }

  .international-order-line__col--no-margin {
    margin-top: 0px;
  }

  .international-order-line__buttons {
    margin-left: 25px;
  }
  .international-order-line__button {
    text-decoration: none;
    padding: 0px;
    margin-right: 9px;

    i {
      color: $c-icon-gray;
    }

    i.international-order-line__icon--orange {
      color: $c-icon-orange;
    }
  }

  .international-order-line__input-group {
    display: flex;
  }

  .international-order-line__label .international-order-line__specifications {
    font-weight: 400px;
    display: inline-block;
  }
}

.ant-popover {
  z-index: 1050 !important;
}
