@import '../../../style-vars';

.next-gen-layout-container {
  display: flex;
  flex-direction: column;

  .header-bar {
    height: $header-height;
    width: 100%;
    position: fixed;
    background-color: $header-background-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    padding-left: 7px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;

    .header-bar-page-title {
      margin-left: 20px;
      color: $header-text-color;
      font-family: var(--chakra-fonts-heading);
      font-size: 24px;
    }

    .main-logo {
      img {
        width: auto;
        height: $logo-height;
      }
    }
  }
  .header-spacer {
    height: $header-height;
  }

  .app-content {
    display: flex;
    flex-direction: row;
    .main-content {
      flex: 1;
      max-width: 100%;
      width: calc(100% - 76px);
    }
  }

  .analytic-dashboard-container {
    margin-left: -48px;
    margin-right: -48px;

    .ant-layout-content {
      padding-top: 24px;
      padding-left: 48px;
      padding-right: 48px;
    }
  }
}
