.bulk-updater__results-grid {
  overflow-y: overlay;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .rdg-row.status-cancelled {
    position: absolute;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: var(--chakra-colors-red-100);
      height: calc(100% - 1px);
      width: 100%;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .nested__results-grid-cell {
    padding-left: 0;
    padding-right: 0;
    margin-left: -2px;
  }
  .frozen__cell {
    background-color: white;
  }
}

.selected_data_grid_row {
  background-color: inherit !important;
}
.rdg-cell[aria-sort='ascending'] {
  span + span:after {
    content: '' !important;
  }
}
.rdg-cell[aria-sort='descending'] {
  span + span:after {
    content: '' !important;
  }
}
.rdg-header-sort-name + span:after {
  content: ' \f0dc' !important;
  font-family: 'Font Awesome 5 Free' !important;
  display: inline-block !important;
  font-weight: 900 !important;
  vertical-align: middle !important;
  margin-left: 5px;
}
