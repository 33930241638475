// Ant Design
@import '../../node_modules/antd/dist/antd.min.css';

.ant-popover-content {
  .ant-btn-sm {
    float: right;
  }
  .ant-btn-sm.ant-btn-primary {
    float: none;
  }
}

.form-control {
  font-family: var(--chakra-fonts-body);
}


.ant-picker-input > input:disabled {
  color: #9fa6ad;
  font-weight: 500;
}


// File Uploader for Food Safety & Quality
.file-uploader {
  .ant-upload {
    height: auto;

    .ant-upload-btn,
    .ant-upload-drag-container {
      display: block;
      height: auto;
    }

    .ant-upload-btn {
      padding: 0;
    }
  }
}