.pending-order-page {
  .req-field {
    // background: white;

    //font-weight: bold;
    &.actions {
      button {
        padding: 4px;
        margin: 0 2px;

        span {
          margin: 0;
        }
      }
    }
  }

  .btn-container {
    min-width: 150px;
  }

  .cancelled-order {
    background: 'rgba(255,0,0,0.2)' !important;
    // background: '#fff4ee' !important;
  }

  .order-results-table .table td,
  .table th {
    border-top: none;
  }

  .order-results-table .table .m-table.m-table--head-separator-danger thead th:first-child,
  .m-table.m-table--head-separator-danger tbody td:first-child {
    vertical-align: sub;
  }

  .m-table.m-table--head-separator-danger thead th {
    text-align: left;
  }
}
