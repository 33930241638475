.daily-tasks-admin {
  .daily-tasks-admin__container {
  }

  .daily-tasks-admin__card {
    min-height: 500px;
    margin-left: 10px;
    margin-top: 10px;
    padding: 40px 30px 30px;
  }

  .daily-tasks-admin__card-title {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    border-bottom: 1px solid #dcdcdc;
    padding: 20px;
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
  }

  .daily-tasks-admin__button--nav {
    margin-left: 20px;
  }

  .daily-tasks-admin__content {
    width: 100%;
    padding-top: 30px;
  }
}

// ----------------------------------
// User Assignment
// ----------------------------------
.user-assignment {
  margin-bottom: 30px;
  max-width: 600px;

  .user-assignment__label {
    padding-bottom: 5px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid #dcdcdc;
    display: flex;
    justify-content: space-between;
  }

  .user-assignment__name {
    width: 300px;
    display: inline-block;
    color: gray;
  }

  .user-assignment__button {
    padding: 0px;
    color: gray;
  }

  .user-assignment__grinder {
    display: inline-block;
    padding: 3px 13px;
    border-radius: 3px;
    background-color: #929292;
    color: white;
    font-size: 13px;
    font-weight: 400;
    margin-right: 14px;
    margin-bottom: 5px;
  }

  .user-assignment__grinder--usa {
    background-color: #945572;
  }

  .user-assignment__grinder--aus,
  .user-assignment__grinder--australia {
    background-color: #5a8ec3;
  }

  .user-assignment__grinder--nz,
  .user-assignment__grinder--new-zealand {
    background-color: #559055;
  }

  i {
    padding-left: 20px;
  }
}
