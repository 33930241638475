$c-gray: #36404a;
$c-icon-orange: #ec864f;

.comments {
  width: 100%;
  .comments__col {
    margin-top: 17px;
  }

  hr {
    margin: 24px 0px;
  }

  .comments__header {
    font-family: Helvetica;
    font-size: 20px;
    line-height: 1.4;
    color: $c-gray;
  }

  .comments__collapse-header {
    i {
      color: $c-icon-orange;
      padding-right: 8px;
      position: relative;
      top: 2px;
    }
  }
}
