.Qps_table {
  width: calc(50% - 10px);
}

.Qps_table:first-child {
  margin-right: 10px;
}

.Qps_table:last-child {
  margin-left: 10px;
}

.Qps_tableRow {
  display: flex;
  padding: 1px 10px 1px 0;
  align-items: center;
}

.Qps_radio {
}

.Qps_title {
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 8px;
  text-transform: uppercase;
  font-size: 16px;
  color: #0b1435;
  opacity: 0.6;
  margin-bottom: 12px;
  font-weight: 500;
}

.Qps_label {
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Qps_score {
  font-weight: bold;
  margin-right: 20px;
  background-color: #f2f2f2;
  padding: 4px 0;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  text-align: center;
  position: relative;
}

.Qps_scoreMulti {
  width: 10px;
  position: absolute;
  opacity: 0.5;
}

.Qps_score .Qps_tag {
  width: 9px;
  height: 9px;
  position: absolute;
  bottom: 7px;
  right: 0;
}

.Qps_radio :global(.ant-radio-button-checked + span) {
  font-weight: bold;
}

.Qps_score .Qps_pointer {
  height: 10px;
  margin: auto;
  position: relative;
  bottom: -4px;
}

.Qps_historyTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
}

.Qps_historyTitle .Qps_label {
  text-transform: uppercase;
  color: #0b1435;
  opacity: 0.6;
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 16px;
}

.Qps_keyStatistic {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: #0b1435;
}

.Qps_keyStatistic span {
  font-size: 30px;
  font-weight: bolder;
  color: black;
}

.Qps_toggle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
