.buy-activity-dashboard {
  font-family: Poppins, sans-serif;

  .buy-activity-dashboard__header {
    position: relative;
    text-align: left;
    margin-bottom: 10px;
  }

  .buy-activity-dashboard__header-title {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
  }

  .buy-activity-dashboard__main-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .buy-activity-timestamp {
    display: none;
  }
}
