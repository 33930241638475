.tooltip {
  text-align: center;
  background-color: white;
  padding: 5px 10px;
  border-radius: 2px;
  border: 1px solid lightgrey;
  font-size: 12px;
  position: absolute;
}

.tooltipTitle {
  text-align: center;
}

.tooltipRow {
  display: flex;
}

.tooltipNumber {
  width: 25%;
  text-align: end;
  font-weight: bold;
  margin-right: 5px;
}

.tooltipLabel {
  text-align: start;
}