$c-gray: var(--chakra-colors-gray-800);
.international-order-form {

  .international-order-form__header {
    font-family: Helvetica;
    font-size: 20px;
    line-height: 1.4;
    color: $c-gray;
  }

  .international-order-form__toggle {
    .thermometer {
      color: white;
      font-size: 15px;
    }
  }

}
