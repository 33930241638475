.sticky_first_column tr > td:first-child,
.sticky_first_column tr > th:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}

.sticky_first_column tr > th:first-child {
  background-color: #F7FAFC;
}