.report-params-config {
  .report-params-config__run-btn {
    float: right;
    height: 100px;
    padding: 25px;
  }

  .report-params-config__container {
    padding-right: 10px;
    float: left;
    margin-right: 80px;
    margin-bottom: 24px;
    width: 265px;
  }
}
