.cowVis {
  display: grid;
  grid-gap: 0px 6px;
  grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
}

.cowVisIcon {
  position: relative;
  width: 39px;
}

.cowVisIconCoverUp {
  background-color: #ffffff;
  position: absolute;
  right: -5px;
  height: 100%;
  top: 0;
}

.dataRow {
  display: flex;
  width: 100%;
  margin: 10px 0;
  align-items: flex-start;
}

.dataVis {
  width: 63%;
  margin: 0 20px;
}

.dataNumbers {
  display: flex;
  align-items: baseline;
  width: 100%;
}

.bold {
  font-weight: bold;
}

.legend {
  border-top: 1px solid #f2f2f2;
  padding-top: 10px;
  /* height: 20px; */
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
}

.legend svg {
  width: 29px;
  margin-right: 10px;
}

.percentage {
  width: 75px;
}

.percentage svg {
  width: 15px;
  height: 15px;
}

.options {
  margin-left: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.flag {
  width: 40px;
  margin-right: 10px;
  display: inline-block;
}

.statistic :global(.ant-statistic-title) {
  margin-bottom: 3px;
  font-weight: 500;
}

.statistic :global(.ant-statistic-content) {
  line-height: 30px;
}

.statistic :global(.ant-statistic-content-value) {
  font-size: 30px;
}

.statistic :global(.ant-statistic-content-prefix) {
  font-weight: 500;
  margin-right: 0;
  font-size: 30px;
  line-height: 30px;
  font-weight: normal;
}

.statistic :global(.ant-statistic-content-suffix) {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}

.keyDataRow {
  display: flex;
}

.keyDataRow > div {
  margin-right: 25px;
  margin-bottom: 21px;
}

.keyDataRow > div:last-of-type {
  margin-right: 0;
  flex-wrap: wrap;
}

.subtext {
  padding-top: 8px;
  font-style: italic;
  margin-bottom: -25px;
}
