.rejected-carton {
  .ant-col {
    margin-bottom: 15px;
  }

  .rejected-carton__icon {
    color: #8f9bb3;
  }

  .rejected-carton__icon--orange {
    color: #ec864f;
  }

  .rejected-carton__controls {
    padding-top: 30px;
    display: flex;

    .ant-btn {
      padding: 0 5px;
    }
  }

  .rejected-carton__info {
    color: gray;
    padding-left: 7px;
    cursor: pointer;
  }

  @media (min-width: 1400px) {
    .rejected-carton__row {
      padding-right: 100px;
      max-width: 1800px;
    }
  }

  .rejected-carton__totals {
    display: flex;
  }

  .rejected-carton__total {
    margin: 5px 40px 15px 0;
    display: flex;
  }
  .rejected-carton__label {
    padding-left: 5px;
    padding-right: 10px;
    font-weight: bold;
  }
}
