.invoice-totals {
  font-family: Helvetica;
  color: var(--chakra-colors-actionNeutral-darkGray);
  border-bottom: 2px solid var(--chakra-colors-actionNeutral-gray3);
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;

  .invoice-totals__container {
    display: flex;
  }

  .invoice-totals__button {
    margin-bottom: 30px;
    margin-right: 30px;
    background-color: var(--chakra-colors-actionPrimary-blueButton);
    color: var(--chakra-colors-neutral-white);
  }
  .invoice-total {
    padding: 0 10px;

    &.invoice-total--border {
      border-left: 2px solid var(--chakra-colors-actionNeutral-darkGray);
    }

    .invoice-total__amount-container {
      display: flex;
      align-items: baseline;
      height: 32px;
    }

    .invoice-total__amount {
      font-size: 25px;
      line-height: 0.73;
    }

    .invoice-total__unit {
      font-size: 15px;
      line-height: 1.47;
      text-align: right;
      padding-left: 6px;
    }

    .invoice-total__label {
      font-size: 14px;
      line-height: 1.47;
      text-align: right;
      padding-bottom: 14px;
    }
  }

  @media (min-width: 1200px) {
    .invoice-total {
      padding: 0 30px;

      .invoice-total__amount {
        font-size: 30px;
        line-height: 0.73;
      }
    }
  }
}
