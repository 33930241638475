.bar {
  background-color: #bdbdbd;
  color: black;
  font-weight: bold;
  text-align: end;
  padding-right: 5px;
  white-space: nowrap;
}

.table {
  width: 50%;
}

.tableRow {
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 0;
}

.title {
  border-bottom: 1px solid #d2d2d2;
  text-transform: uppercase;
  font-size: 16px;
  color: #d2d2d2;
}

.label {
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categoryBadge {
  width: 12px;
  height: 12px;
  border-radius: 2px;
}