.colouredCheckboxGroup {
  padding-left: 0;
}

.colouredCheckboxGroup li {
  display: block;
}

.colouredCheckbox09AFA3 :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #09afa3;
  border-color: #09afa3;
}

.colouredCheckboxFF1577 :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #ff1577;
  border-color: #ff1577;
}

.colouredCheckbox5039B5 :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #5039b5;
  border-color: #5039b5;
}

.colouredCheckboxFC5D36 :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #fc5d36;
  border-color: #fc5d36;
}

.colouredCheckbox00B5F2 :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #00b5f2;
  border-color: #00b5f2;
}

.colouredCheckboxFED000 :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #fed000;
  border-color: #fed000;
}

.colouredCheckbox0B1435 :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #0b1435;
  border-color: #0b1435;
}

.colouredCheckboxAB2424 :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #ab2424;
  border-color: #ab2424;
}
