.Quality_quality :global(.base-chart .tick-x) {
  font-size: 14px;
  font-weight: bold;
}

.Quality_comingSoon {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  border-radius: 5px;
}

.Quality_comingSoon p {
  font-size: 18px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.3);
  transform: rotate(-15deg);
}
