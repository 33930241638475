.checkboxEntity-1CA6B2 :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1ca6b2 !important;
  border-color: #1ca6b2 !important;
}

.checkboxEntity-F01D82 :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #F01D82 !important;
  border-color: #F01D82 !important;
}

.checkboxEntity-805CFF :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #805CFF !important;
  border-color: #805CFF !important;
}

.checkboxEntity-F2C94C :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #F2C94C !important;
  border-color: #F2C94C !important;
}

.checkboxEntity-00B5F2 :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00B5F2 !important;
  border-color: #00B5F2 !important;
}

.checkboxEntity-FC5D36 :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FC5D36 !important;
  border-color: #FC5D36 !important;
}

.checkboxEntity-006df2 :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #006df2 !important;
  border-color: #006df2 !important;
}

.checkboxEntity-FF9A3D :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FF9A3D !important;
  border-color: #FF9A3D !important;
}

.checkboxEntity-545B72 :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #545B72 !important;
  border-color: #545B72 !important;
}

.checkboxEntity-AB2424 :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #AB2424 !important;
  border-color: #AB2424 !important;
}

.checkboxEntity-029916 :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #029916 !important;
  border-color: #029916 !important;
}
