.coldstore-inventory-table-item {
  .table-calendar .rdtPicker {
    right: 0;
  }

  .table-calendar .rdtPicker td {
    padding: 0 !important;
    vertical-align: middle;
  }

  .table-calendar th {
    text-align: center !important;
    padding: var(--chakra-space-2) !important;
    background: var(--chakra-colors-white) !important;
    color: var(--chakra-colors-gray-600) !important;
  }

  .table-calendar .table th {
    padding: var(--chakra-space-3) !important;
  }

  .table-calendar .rdt div input {
    width: 110px;
  }
}

.table-calendar {
  .rdtPicker {
    width: 250px !important;
  }

  .ant-picker-input > input {
    font-size: 12px;
  }
}
