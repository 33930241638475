$color-primary: #ec864f;
$color-secondary: #63729d;
$color-disabled: #c5cee0;
$color-white: #ffffff;

.edit-release-modal__content {
  text-align: center !important;

  .ant-collapse-content {
    overflow: initial !important;
  }

  .ant-btn.edit-release-modal__cancel-btn {
    text-decoration: none;
    border: none;
  }

  .ant-btn.edit-release-modal__secondary-btn {
    float: left;
    background-color: $color-secondary;
    color: $color-white;
    width: 156px;
    height: 40px;
    border-radius: 3px;
  }

  .edit-release-modal__primary-btn {
    background-color: $color-primary;
    margin: 0 0 30px 10px;
    color: $color-white;
    width: 156px;
    height: 40px;
    border-radius: 3px;
  }

  .ant-btn.edit-release-modal__primary-btn.ant-btn[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-modal-footer {
    border: none !important;
    margin-top: 30px !important;
  }

  .ant-modal-header {
    height: 45px !important;
    border-radius: 2px !important;
    background-color: #f7f9fc !important;

    .ant-modal-title {
      height: 28px;
      font-family: Helvetica;
      font-size: 20px !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.1;
      letter-spacing: normal;
      text-align: center !important;
      color: #36404a !important;
    }
  }

  .edit-release-modal__header-description {
    height: 42px !important;
    font-family: Helvetica !important;
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8 !important;
    letter-spacing: normal;
    color: #4a4a4a !important;
    margin-left: 13%;
    margin-bottom: 20px;
  }

  .edit-release-modal__line-spearator {
    width: 100%;
    height: 2px;
    border: solid 2px #848484;
    display: flex;
  }

  .edit-release-modal__email {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .edit-release-modal__grinder-table-actions {
    margin-top: 30px;
  }

  .edit-release-modal__email-body-heading {
    font-family: Helvetica;
    font-size: 18px;
    text-align: center;
    color: var(--chakra-colors-gray-600);
  }

  .edit-release-modal__email-subject {
    font-weight: bolder;
  }

  .edit-release-modal__email-body {
    margin-top: var(--chakra-space-4);
    width: 100%;
    height: var(--chakra-sizes-sm);
    border: solid 1px var(--chakra-colors-gray-500);
  }

  .edit-release-modal__email-body-content textarea {
    border: none !important;
    text-decoration: none;
    resize: none;
  }

  .edit-release-modal__email-body-content {
    margin: 20px 0 0 8px;
    font-weight: normal !important;
  }

  .edit-release-modal__email-attachment-heading {
    margin-bottom: var(--chakra-space-4);
    font-family: Helvetica;
    font-size: 14px;
    color: var(--chakra-colors-gray-600);
  }

  .edit-release-modal__email-child-attachment {
    width: var(--chakra-sizes-9);
    height: var(--chakra-sizes-8);
    border: solid 1px var(--chakra-colors-gray-500);
  }

  .edit-release-modal__email {
    position: relative;
  }

  .edit-release-modal__email-btn {
    margin: 106px 0 0 28px;
  }

  .edit-release-modal__email-btn button {
    width: 143px;
    height: 36px;
    border-radius: 2px;
  }

  .email-recipients {
    text-align: left;
  }

  .edit-release-modal__email-preview {
    text-align: left;
  }

  .edit-release-modal__alert {
    margin-top: 30px;
    font-weight: normal;
  }
}
