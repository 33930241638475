.coldstore-details {
  .coldstore-details__container-temperatures {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 10px 0 15px 10px;
    width: 80%;
  }

  .coldstore-details__container-temperature-over-red-line-box {
    display: flex;
  }

  .coldstore-details__container-temperature-over-red-line-input {
    bottom: 0;
    position: absolute;
    zoom: 1.8;
    width: auto;
  }

  .coldstore-details__container-temperature-over-red-line-label {
    bottom: 0;
    position: absolute;
    margin-left: 30px;
  }
}
