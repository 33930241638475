@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap);

$font-family: 'Poppins', serif;

// colors
$primary-color: #0b1435;
$highlight-color: hsl(12, 97%, 60%);
$light-grey-color: #f0f2f5;
$grey-color: #e6e8eb;
$grey-color-2: #f2f2f2;
$dark-grey-color: rgba(11, 20, 53, 0.7);
$white-color: #fff;

// general
$layout-body-background: $light-grey-color;

// tabs
$tabs-ink-bar-color: $highlight-color;

// radio buttons (filter switches)
$radio-button-bg: $grey-color;
$radio-button-checked-bg: black; //btn-default-bg;
$radio-button-color: $dark-grey-color;
$border-color-base: $light-grey-color;

/* customisation for Ant Design components */

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

.ant-statistic-title {
  text-transform: uppercase;
}

.ant-statistic-content-value {
  font-weight: bold;
}

.ant-statistic-content-suffix {
  color: $dark-grey-color;
}

.ant-card {
  border-radius: 5px;
  color: $primary-color;
}

.ant-card-bordered {
  border: none;
}

.ant-list-items {
  display: flex;
  flex-wrap: wrap;
}

.ant-list-item {
  width: 100%;
  padding: 12px 0 !important;
  border-bottom: none !important;
}

.ant-collapse,
.ant-collapse-content {
  color: $primary-color;
}

.ant-collapse-content-box {
  padding: 16px 0 !important;
}
.ant-collapse-arrow {
  color: $highlight-color !important;
  left: 0 !important;
}

.ant-collapse-header {
  padding-left: 20px !important;
}

.ant-radio-button-wrapper {
  color: $dark-grey-color;
  background-color: $grey-color-2;
}

.ant-radio-button-wrapper:hover,
.ant-radio-button-wrapper-checked,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: $primary-color;
}

.ant-radio-button-wrapper-checked,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: $white-color;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before,
.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: $grey-color;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-button-wrapper,
.ant-radio-button-wrapper:first-child {
  border-color: $grey-color;
}

.smallPanel .ant-collapse-header {
  padding: 6px 16px 6px 26px !important;
}

.smallPanel .ant-collapse-icon-position-right .ant-collapse-arrow {
  color: $primary-color !important;
  opacity: 0.6;
}
