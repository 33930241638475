.QualityClaims_label {
  text-transform: uppercase;
  color: rgba(11, 20, 53, 0.6);
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 1600px) {
  .QualityClaims_label {
    max-width: 200px;
  }
}

.QualityClaims_count,
.QualityClaims_amount,
.QualityClaims_period {
  font-family: Poppins, sans-serif;
}

.QualityClaims_claimsInfo .QualityClaims_count {
  font-size: 30px;
  font-weight: bold;
}

.QualityClaims_claimsInfo .QualityClaims_amount {
  font-size: 22px;
  font-weight: bold;
}

.QualityClaims_claimsInfo .QualityClaims_period {
  font-size: 22px;
}

.QualityClaims_infoBright .QualityClaims_count,
.QualityClaims_infoBright .QualityClaims_amount {
  color: #000000;
}

.QualityClaims_infoBright .QualityClaims_period {
  color: #0b1435;
}

.QualityClaims_infoBright .QualityClaims_dataPoints {
  color: #666666;
}

.QualityClaims_infoLight .QualityClaims_count,
.QualityClaims_infoLight .QualityClaims_amount,
.QualityClaims_infoLight .QualityClaims_period {
  color: #000000;
  opacity: 0.4;
}

.QualityClaims_infoLight .QualityClaims_dataPoints {
  color: #a3a3a3;
}

.QualityClaims_byCategoryTitle,
.QualityClaims_packerPlantsTitle,
.QualityClaims_incidentsTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.QualityClaims_byCategoryOptions {
  display: flex;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(210, 210, 210, 0.5);
}

.QualityClaims_byCategoryOptions :global(.ant-checkbox-inner),
.QualityClaims_byCategoryOptions :global(.ant-checkbox-wrapper:hover .ant-checkbox-inner),
.QualityClaims_byCategoryOptions :global(.ant-checkbox:hover .ant-checkbox-inner),
.QualityClaims_byCategoryOptions :global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
  border: solid 2px #fc5d36;
  border-radius: 2px;
}

.QualityClaims_byCategoryOptions :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #fc5d36;
}

.QualityClaims_byCategoryOptions :global(.ant-checkbox-checked .ant-checkbox-inner:after) {
  top: 5px;
  left: 2px;
}

.QualityClaims_tableRow {
  display: flex;
  padding: 5px 0;
  height: 28px;
  align-items: center;
}

.QualityClaims_tableRowSecondary {
  color: rgba(11, 20, 53, 0.6);
}

.QualityClaims_tableRowValues {
  text-align: right;
  white-space: nowrap;
}

.QualityClaims_claimAmount :global(.base-chart .tick-x):before {
  content: '$';
  font-weight: normal;
}

.QualityClaims_differenceArrow {
  display: inline-block;
  width: 20px;
  position: relative;
  top: 5px;
}

.QualityClaims_viewAll,
.QualityClaims_viewAll:hover {
  color: rgba(11, 20, 53, 0.6);
  margin-top: 10px;
  display: block;
  width: fit-content;
}

.QualityClaims_radio :global(.ant-radio-button-checked + span) {
  font-weight: bold;
}

@media screen and (max-width: 1300px) {
  .QualityClaims_radio:global(.ant-radio-group) {
    display: flex;
    flex-direction: column;
    margin-right: 60px;
  }
}
