.mainChartContainer {
  display: flex;
}

.tickXContainer {
  width: 50px;
  position: relative;
}

.chartContainer {
  width: calc(100% - 50px);
  position: relative;
}

.rankingChartSvg {
  height: 100%;
  width: 100%;
}

.rowContainer {
  height: 50px;
  position: relative;
  margin-left: 50px;
}

.rank {
  position: absolute;
  font-size: 12px;
  text-align: end;
  width: 100%;
}

.tooltip {
  background-color: white;
  padding: 5px 10px;
  border-radius: 2px;
  border: 1px solid lightgrey;
  font-size: 12px;
  position: absolute;
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tooltipPercent {
  width: 30%;
  font-weight: bold;
}

.tooltipBadge {
  border-radius: 2px;
  margin-right: 5px;
  width: 10px;
  height: 10px;
}

.tooltipName {
  width: calc(70% - 15px);
  display: flex;
  align-items: center;
}
