$c-slider-font: #fff;
$c-toggle-blue: #007eff;

.cf-toggle {
  min-width: 264px !important;
  width: 100%;
  height: 37px !important;
  .btn.btn-default:hover {
    background-color: var(--chakra-colors-actionPrimary-default);
  }

  i {
    padding-right: 5px;
  }

  .cf-toggle__slider {
    background-color: var(--chakra-colors-actionPrimary-default);
    height: 37px;
    font-family: Helvetica;
    font-size: 14px;
    padding: 0;
    text-align: center;
    color: var(--chakra-colors-white);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cf-toggle__slider--on {
    padding-right: 22px;
  }

  .cf-toggle__slider--off {
    padding-left: 22px;
  }
}

.product-type__switch {
  svg {
    display: inline;
  }
}
