.purchase-order-form {
  .purchase_order_form__alert {
    max-width: 740px;
    margin: 6px 0px 20px;
  }

  .row {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  hr {
    margin-top: 45px;
  }

  .btn-success {
    margin-bottom: 30px;
  }

  .purchase-order-line {
    margin-top: 20px;
  }
}
.css-r447wv-singleValue {
  color: black !important;
  opacity: 1 !important;
  font-weight: bold !important;


}
.css-102ttgf-singleValue {
  color: black !important;
  opacity: 1 !important;
  font-weight: bold !important;


}
.chakra-input {
  color: black !important;
  opacity: 1 !important;
  font-weight: bold !important;



}
.form-control.m-input {
  color: black !important;
  opacity: 1 !important;
  font-weight: bold !important;


}
.form-control {
  color: black !important;
  opacity: 1 !important;
  font-weight: bold !important;


}
.chakra-input:disabled {
  opacity: 1 !important;
}