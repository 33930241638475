// TODO, move to parent
// issue, moving under parent doesn't apply css
.current_affairs {
}
.current_affairs__child-info-data {
  text-decoration: underline;
}
.current_affairs__child-info-arrow {
  color: royalblue;
  margin: 0 5px 0 10px;
}
.current-affairs__info-box {
  z-index: 1000;
  position: fixed;
  width: 300px;
  height: 100px;
  color: grey;
  top: 15px;
  margin-left: 10px;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
