.datePicker :global(.ant-picker-input input) {
  font-weight: bold;
}

.radioGroup :global(.ant-radio-button-checked + span) {
  font-weight: bold;
}

.selectBox :global(.ant-select-selection-item) {
  font-weight: bold;
}

.checkboxGroup:global(.ant-checkbox-group) {
  display: flex;
  flex-direction: column;
}

.selectBox,
.radioGroup,
.checkboxGroup {
  width: 100%;
  margin-top: 5px;
}

.checkboxGroup :global(.ant-checkbox-wrapper + .ant-checkbox-wrapper) {
  margin-left: 0;
}

.filterLabel {
  display: block;
  text-transform: uppercase;
  color: #0b1435;
  opacity: 0.6;
  font-weight: 500;
  font-size: 16px;
}

.checkbox[data-color='#09AFA3'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #09afa3;
  border-color: #09afa3;
}
.checkbox[data-color='#09AFA3'] :global(.ant-checkbox-checked)::after {
  border-color: #09afa3;
}

.checkbox[data-color='#FF1577'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #ff1577;
  border-color: #ff1577;
}
.checkbox[data-color='#FF1577'] :global(.ant-checkbox-checked)::after {
  border-color: #ff1577;
}

.checkbox[data-color='#5039B5'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #5039b5;
  border-color: #5039b5;
}
.checkbox[data-color='#5039B5'] :global(.ant-checkbox-checked)::after {
  border-color: #5039b5;
}

.checkbox[data-color='#FC5D36'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #fc5d36;
  border-color: #fc5d36;
}
.checkbox[data-color='#FC5D36'] :global(.ant-checkbox-checked)::after {
  border-color: #fc5d36;
}

.checkbox[data-color='#00B5F2'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #00b5f2;
  border-color: #00b5f2;
}
.checkbox[data-color='#00B5F2'] :global(.ant-checkbox-checked)::after {
  border-color: #00b5f2;
}

.checkbox[data-color='#FED000'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #fed000;
  border-color: #fed000;
}
.checkbox[data-color='#FED000'] :global(.ant-checkbox-checked)::after {
  border-color: #fed000;
}

.checkbox[data-color='#4D536A'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #4d536a;
  border-color: #4d536a;
}
.checkbox[data-color='#4D536A'] :global(.ant-checkbox-checked)::after {
  border-color: #4d536a;
}

.checkbox[data-color='#AB2424'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #ab2424;
  border-color: #ab2424;
}
.checkbox[data-color='#AB2424'] :global(.ant-checkbox-checked)::after {
  border-color: #ab2424;
}

.checkbox[data-color='#FEC5B8'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #fec5b8;
  border-color: #fec5b8;
}
.checkbox[data-color='#FEC5B8'] :global(.ant-checkbox-checked)::after {
  border-color: #fec5b8;
}

.checkbox[data-color='#DCC48E'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #dcc48e;
  border-color: #dcc48e;
}
.checkbox[data-color='#DCC48E'] :global(.ant-checkbox-checked)::after {
  border-color: #dcc48e;
}

.checkbox[data-color='#3DE5C7'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #3de5c7;
  border-color: #3de5c7;
}
.checkbox[data-color='#3DE5C7'] :global(.ant-checkbox-checked)::after {
  border-color: #3de5c7;
}

.checkbox[data-color='#D0F1EE'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #d0f1ee;
  border-color: #d0f1ee;
}
.checkbox[data-color='#D0F1EE'] :global(.ant-checkbox-checked)::after {
  border-color: #d0f1ee;
}

.checkbox[data-color='#E3E8F9'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #e3e8f9;
  border-color: #e3e8f9;
}
.checkbox[data-color='#E3E8F9'] :global(.ant-checkbox-checked)::after {
  border-color: #e3e8f9;
}

.checkbox[data-color='#F8617B'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #f8617b;
  border-color: #f8617b;
}
.checkbox[data-color='#F8617B'] :global(.ant-checkbox-checked)::after {
  border-color: #f8617b;
}

.checkbox[data-color='#4E5984'] :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #4e5984;
  border-color: #4e5984;
}
.checkbox[data-color='#4E5984'] :global(.ant-checkbox-checked)::after {
  border-color: #4e5984;
}

div[data-vertical='true'] .radioGroup {
  display: flex;
  flex-direction: column;
}

div[data-vertical='true'] .radioGroup :global(.ant-radio-button-wrapper) {
  border-radius: 0;
}

div[data-vertical='true'] .radioGroup :global(.ant-radio-button-wrapper:first-of-type) {
  width: calc(100% + 1px);
  left: -1px;
}

div[data-all-selected='true'] .radioGroup :global(.ant-radio-button-wrapper) {
  background-color: #fff;
}

.colorIndicator {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  top: 1px;
  margin-right: 10px;
}

.colorIndicator[data-color='#09AFA3'] {
  background-color: #09afa3;
}

.colorIndicator[data-color='#FF1577'] {
  background-color: #ff1577;
}

.colorIndicator[data-color='#5039B5'] {
  background-color: #5039b5;
}

.colorIndicator[data-color='#FC5D36'] {
  background-color: #fc5d36;
}

.colorIndicator[data-color='#00B5F2'] {
  background-color: #00b5f2;
}

.colorIndicator[data-color='#FED000'] {
  background-color: #fed000;
}

.colorIndicator[data-color='#4D536A'] {
  background-color: #4d536a;
}

.colorIndicator[data-color='#AB2424'] {
  background-color: #ab2424;
}

.colorIndicator[data-color='#FEC5B8'] {
  background-color: #fec5b8;
}

.colorIndicator[data-color='#DCC48E'] {
  background-color: #dcc48e;
}

div[data-with-colors='true'] .selectBox :global(.ant-select-selection-item::before) {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  top: 1px;
  margin-right: 10px;
}

div[data-color='#09AFA3'] .selectBox :global(.ant-select-selection-item::before) {
  background-color: #09afa3;
}

div[data-color='#FF1577'] .selectBox :global(.ant-select-selection-item::before) {
  background-color: #ff1577;
}

div[data-color='#5039B5'] .selectBox :global(.ant-select-selection-item::before) {
  background-color: #5039b5;
}

div[data-color='#FED000'] .selectBox :global(.ant-select-selection-item::before) {
  background-color: #fed000;
}

div[data-color='#00B5F2'] .selectBox :global(.ant-select-selection-item::before) {
  background-color: #00b5f2;
}

div[data-color='#4E5984'] .selectBox :global(.ant-select-selection-item::before) {
  background-color: #4e5984;
}
