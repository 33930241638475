.claim-list-form {
  td {
    padding-bottom: 17px;
    box-shadow: none;
  }

  .error-row {
    background-color: #fff8f8;
    border: 1px solid #c62704;

    td {
      box-shadow: 0px 1px 0px 0px #c62704 inset, 0px -1px 0px 0px #c62704 inset;
    }
  }

  .chakra-popover__body {
    p {
      line-height: 24px;
      b {
        color: #c62704;
      }
    }
  }

  .rdtPicker__right {
    .rdtPicker {
      bottom: 0;
      left: 65%;
    }
  }
}
