.QualityDetailsNCRs_quality :global(.base-chart .tick-x) {
  font-size: 14px;
  font-weight: bold;
}

.QualityDetailsNCRs_sideBarFilterContainer {
  margin-bottom: 20px;
}

.QualityDetailsNCRs_sideBarFilterContainer :global(.ant-checkbox-disabled + span) {
  color: rgba(0, 0, 0, 0.85);
  cursor: not-allowed;
}
