.document-results {
  .results-grid {
    .rdg {
      font-weight: normal;
    }
    .rdg-light {
      border-bottom: 1px solid transparent;
      border-right: 1px solid transparent;
      border-left: 1px solid transparent;
      border-radius: 6px;
    }

    .rdg-theme--narrow {
      .rdg-header-row {
        background-color: #fff;
      }
    }

    .rdg-header-row {
      background-color: var(--chakra-colors-gray-100);
    }

    .rdg-header-row,
    .rdg-row {
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom-width: 0px;
      width: 100% !important;
    }

    .rdg-cell-frozen {
      border-right: 1px solid #ccc;
    }

    .rdg-cell {
      font-size: 12px;
      color: #5a5a5a;
      border-left-color: transparent;
      border-right-color: transparent;
      padding: 0 21px;
    }

    .rdg-cell[role='columnheader'] {
      line-height: 14px;
      display: flex;
      align-items: center;
      word-wrap: normal;
      white-space: normal;
    }

    &.disable-select {
      .rdg-row {
        background-color: unset;
      }
      .rdg-cell {
        box-shadow: none;
      }
    }

    .rdg-checkbox {
      width: 16px;
      height: 16px;
      border: 1px solid #ccc;
      border-radius: 3px;
    }

    .rdg-checkbox-input:checked + .rdg-checkbox {
      box-shadow: none;
      background-color: var(--chakra-colors-actionSecondary-default);
      font-size: 8px;
    }

    .rdg-checkbox-input:checked + .rdg-checkbox::after {
      font-family: 'Font Awesome 5 Free';
      font-weight: 700;
      color: white;
      content: '\f00c';
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }

    .rdg-header-row .rdg-checkbox-input:checked + .rdg-checkbox::after {
      position: relative;
      top: 1px;
      right: -2px;
    }

    /* ------------------ */
    /* PAGINATION */
    /* ------------------ */
    .results-grid__pagination {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 30px;

      .pagination {
        margin-left: 0px;
        margin-right: 0px;
        justify-content: flex-end;
        font-family: HelveticaNeue;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.65);

        i {
          position: relative;
          bottom: 1px;
        }

        li {
          color: #5a5a5a;
          height: 32px;
          min-width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 4px;
          background-color: white;

          a {
            color: #5a5a5a;
          }
        }

        li.active {
          padding: 0px;
          border-width: 2px;
          border-color: var(--chakra-colors-actionSecondary-default);
          a {
            padding: 5px 12px;
            color: var(--chakra-colors-actionSecondary-default);
          }
        }

        li.previous,
        li.next {
          padding: 0px;
          a {
            padding: 15px;
          }
        }
      }

      .results-grid__pagination-stats {
        border: solid 1px #ececec;
        border-radius: 4px;
        padding: 10px;
        color: #808080;
        background-color: white;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        margin-left: 21px;
      }
    }
  }

  .results-grid--minimal {
    .react-grid-Container .react-grid-checkbox + .react-grid-checkbox-label:before {
      width: 15px;
      height: 15px;
      box-shadow: none;
      border-radius: 0px;
    }

    .react-grid-Container .react-grid-checkbox:checked + .react-grid-checkbox-label:before {
      box-shadow: none;
      background-color: var(--chakra-colors-actionSecondary-default) !important;
      border-color: var(--chakra-colors-actionSecondary-default) !important;
      font-family: 'FontAwesome';
      content: '\f00c';
      color: white;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
    }

    .react-grid-Container .row-selected.react-grid-Cell {
      background-color: #ebf5ff !important;
    }

    .react-grid-Container .row-selected .react-grid-Cell__value {
      color: #5a5a5a !important;
    }

    .react-grid-Container .react-grid-HeaderCell {
      padding-left: 13px;
    }
  }
}

.selected_data_grid_row {
  background-color: var(--chakra-colors-actionSecondary-active20) !important;
}
