.covid19_aus {
  .square {
    height: 10px;
    width: 10px;
    border-radius: 5%;
    display: inline-block;
  }

  .triangle-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  .packer_plants {
    background-color: #518ad6;
  }

  .grinders {
    background-color: #51d6b9;
  }

  .covid19-aus__info-box-ul-header {
    color: skyblue;
  }
}
.mapboxgl-popup-content {
  background-color: #fdf5e6;
}
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.covid19-aus__info-box {
  z-index: 1000;
  position: fixed;
  width: 300px;
  height: 100px;
  color: grey;
  top: 15px;
  margin-left: 10px;
}
