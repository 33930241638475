$c-gray: var(--chakra-colors-gray-700);
$c-card-background: var(--chakra-colors-neutral-9);
$c-icon-gray: var(--chakra-colors-gray-500);

.po-card {
  margin-top: 16px;
  border: 0px solid transparent;
  background-color: $c-card-background;

  .po-card__button {
    font-family: Helvetica;
    font-size: 14px;
    color: $c-gray;
    text-decoration: none;
    i {
      padding-right: 4px;
      position: relative;
      top: 2px;
      color: $c-icon-gray;
    }
  }

  .po-card__col {
    margin-bottom: 14px;
  }

  .po-card__label {
    margin-top: 14px;
  }

  .po-card__toggle--large {
    min-width: 120px;
    width: 100%;
  }

  .po-card__card-title {
    padding: 14px 40px 0;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .po-card__card-body {
    padding: 0px 40px 14px;
  }

  .po-card__header {
    font-family: Helvetica;
    font-size: 16px;
    font-weight: bold;
    color: $c-gray;
  }

  /* just for preview */
  .container {
    padding-top: 40px;
  }

  .international-order-lines {
    margin-top: 14px;
  }
}
