.FreshnessDays_title {
  font-size: 22px;
  font-family: Poppins, sans-serif;
  display: flex;
  align-items: center;
}

.FreshnessDays_label {
  text-transform: uppercase;
  color: #0b1435;
  opacity: 0.6;
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
}

.FreshnessDays_newBadge {
  border: 1px solid rgba(11, 20, 53, 0.7);
  color: #0b1435;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  display: inline-block;
  padding: 0px 6px;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 3px;
  position: relative;
  top: -1px;
  opacity: 0.6;
}

.FreshnessDays_hr {
  opacity: 0.6;
  border-color: 1px solid rgba(210, 210, 210, 0.6);
  margin-top: 20px;
}

.FreshnessDays_legend {
  height: 5px;
  width: 30px;
  margin-right: 5px;
}

.freshnessDaysPercentage {
  color: rgba(11, 20, 53, 0.6);
}

.specDefinition {
  color: rgba(11, 20, 53, 0.6);
}

/* .countInSpec :global(.tick-x-container) {
  width: 25px;
}
.countInSpec :global(.tick-x) {
  width: 25px;
}
.countInSpec :global(.chart-container) {
  width: calc(100% - 25px);
} */

.FreshnessDays_lg {
  display: none;
}

@media (min-width: 1480px) {
  .FreshnessDays_lg {
    display: inline;
  }
  .FreshnessDays_xs {
    display: none;
  }
}
