.email-attachment {
  .custom_pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;

    .pagination {
      margin-left: 0px;
      margin-right: 0px;
      justify-content: flex-end;
      font-family: HelveticaNeue;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.65);

      i {
        position: relative;
        bottom: 1px;
      }

      li {
        color: #5a5a5a;
        height: 32px;
        min-width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 4px;
        background-color: white;

        a {
          color: #5a5a5a;
        }
      }

      li.active {
        padding: 0px;
        border-width: 2px;
        border-color: var(--chakra-colors-actionSecondary-default);

        a {
          padding: 5px 12px;
          color: var(--chakra-colors-actionSecondary-default);
        }
      }

      li.previous,
      li.next {
        padding: 0px;

        a {
          padding: 15px;
        }
      }
    }
  }

  .pagination-stats {
    border: solid 1px #ececec;
    border-radius: 4px;
    padding: 10px;
    color: #808080;
    background-color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    margin-left: 21px;
  }
}