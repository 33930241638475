.BaseChart_legend {
  margin: 10px 0;
  display: flex;
  min-height: 22px;
}

.BaseChart_legendItem {
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.BaseChart_legendColor {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 5px;
}

.BaseChart_mainChartContainer {
  display: flex;
}

.BaseChart_tickXContainer {
  width: 50px;
  position: relative;
}

.BaseChart_chartContainer {
  width: calc(100% - 50px);
  position: relative;
}

.BaseChart_svg {
  height: 100%;
  /* width: 100%; */
}

.BaseChart_tooltipLayer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.BaseChart_tooltip {
  text-align: center;
  background-color: white;
  padding: 5px 10px;
  border-radius: 2px;
  border: 1px solid lightgrey;
  font-size: 12px;
  position: absolute;
  z-index: 1;
}

.BaseChart_tooltipTitle {
  text-align: center;
}

.BaseChart_tooltipRow {
  display: flex;
}

.BaseChart_tooltipNumber {
  width: 30%;
  text-align: end;
  font-weight: bold;
  margin-right: 5px;
  flex-shrink: 0;
}

.BaseChart_tooltipLabel {
  text-align: start;
}

.BaseChart_rowContainer {
  height: 50px;
  position: relative;
  margin-left: 45px;
  margin-top: 10px;
}

.BaseChart_YAxis {
  height: 10px;
  width: calc(100% - 50px);
  margin-left: 50px;

}

.BaseChart_tickX {
  width: 50px;
  height: 20px;
  position: absolute;
  text-align: right;
  font-size: 12px;
  white-space: nowrap;
}

.BaseChart_tickY {
  /* width: 40px; */
  height: 40px;
  text-align: center;
  position: absolute;
  transform: rotate(-77deg);
}


.BaseChart_hoverThing:hover {
  fill: rgba(101, 129, 255, 0.08);
}
