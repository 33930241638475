.sustainabilityMetrics {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 16px;
}

.donutContainer {
  position: relative;
}

.donutContainer svg {
}

.donutLine {
  fill: none;
  stroke: #0b1435;
  opacity: 0.4;
}

.donutFill {
  fill: none;
  stroke: #09afa3;
  stroke-width: 15px;
}

.donutContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90px;
}

.count {
  font-size: 26px;
  font-weight: bold;
}
