.downloading {
  .chakra-button {
    display: none;
  }

  .frozen-status__tag {
    padding-bottom: 20px;
  }
  th,
  td {
    padding: 5px;
    padding-bottom: 20px;
  }
  td {
    box-shadow: none;
  }
  .chakra-form__label,
  .chakra-checkbox__label,
  .chakra-radio__label {
    padding-bottom: 14px;
  }

  .dashboard-section__heading {
    padding-bottom: 20px;
  }
}
