.data_import_preview_results_grid {
  overflow-y: overlay;
  border-radius: 6px;
  .rdg-header-row {
    background-color: #f7fafc;
    .rdg-cell {
      text-transform: uppercase;
    }
  }
  .rdg-cell {
    font-size: 14px;
  }
}
