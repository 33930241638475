.finance-page {
  font-family: Helvetica;
  min-width: 900px;

  .finance-page__alert {
    margin-bottom: 10px;
    width: 620px;
    font-size: 13px;
    font-weight: normal;
  }

  p.cye-lm-tag:last-of-type {
    margin-bottom: 0px;
  }

  .finance-page__card {
    border: 0px solid transparent;
    border-radius: 0px;
    // min-height: 88vh;
    min-width: 100vh;
    padding-left: 10px;
    padding-right: 10px;

    .ant-card-head {
      border-bottom: 0px solid transparent;
    }

    .ant-card-head-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.11;
      color: #545454;
      padding: 34px 0 15px;
    }

    .ant-card-body {
      padding-top: 0px;
    }
  }

  .finance-page__results {
    padding-left: 35px;
    padding-right: 35px;
    background-color: var(--chakra-colors-neutral-white);
    min-height: 500px;
  }

  @media (min-width: 1300px) {
    .finance-page__card,
    .finance-page__results {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .finance-page__tab {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.11;
    padding-bottom: 11px;
  }

  .ant-tabs-ink-bar {
    background-color: var(--chakra-colors-4);
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-weight: bold;
    color: var(--chakra-colors-neutral-6);
    padding: 11px 0px;
    margin-right: 65px;
  }

  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: var(--chakra-colors-neutral-3);
    font-weight: bold;
  }

  .ant-tabs-bar {
    margin-bottom: 22px;
    border-bottom: 1px solid transparent;
  }

  .invoice-totals {
    margin: 65px 0 65px 50px;
  }

  .finance-page__cart-toggle {
    width: 541px;
    margin-bottom: -20px;
  }
}
