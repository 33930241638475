.documents-section {
  margin-top: 45px;
  width: 100%;

  .documents-section__header {
    margin-bottom: 25px;
  }

  .documents-section__button {
    margin-bottom: 25px;
    margin-top: 20px;
    display: block;
    background-color: #007eff;
    color: #fff;
  }

  .documents-section__textarea {
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 200px;
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
  }

  .documents-section__radio-group {
    margin-bottom: 20px;
    margin-left: 3px;
  }

  .documents-section__radio-group-heading {
    font-size: 14px;
    font-weight: bold;
  }

  .documents-section__warnings {
    margin-bottom: 20px;
    margin-top: 20px;
    max-width: 650px;
    border-radius: 3px;
  }

  .documents-section__warning {
    padding-left: 25px;
    padding-right: 15px;
  }

  .documents-section__div--field {
    max-width: 200px;
    margin-bottom: 15px;
  }

  .documents-section__label {
    margin-bottom: 3px;
  }

  .Select {
    width: 250px;
    margin-bottom: 20px;
  }

  .cell__actions {
    display: flex;
    align-items: center;
    .ant-btn {
      font-size: 26px;
    }
  }

  .cell__action {
    display: flex;
    align-items: center;
  }
}
