.QualityDetailsClaims_quality :global(.base-chart .tick-x) {
  font-size: 14px;
  font-weight: bold;
}

.QualityDetailsClaims_filterLabel {
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #0b1435;
  opacity: 0.6;
  font-weight: 500;
  font-size: 16px;
}

.QualityDetailsClaims_sideBarFilterContainer {
  margin-bottom: 20px;
}
