.recent-po-title {
  .ant-select-selection {
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  .ant-select-selection--single {
    height: auto;
  }
}

.buy-activity-stream__table {
  width: 100%;

  .buy-activity-stream__table--right {
      text-align: right;
  }

  th {
    padding: 0px 20px 6px 20px;
  }

  td {
    padding: 0px 20px;
  }
}
