.email-recipients {
  .email-recipients__heading {
    font-family: Helvetica;
    font-size: var(--chakra-fontSizes-md);
    font-weight: var(--chakra-fontWeights-bold);
    margin-top: var(--chakra-space-10);
    color: var(--chakra-colors-gray-700);
  }
  .email-recipients__container {
    display: flex;
  }
  .email-recipients__dropdown {
    margin: var(--chakra-space-5) 0;
    height: var(--chakra-size-10);
    width: var(--chakra-sizes-sm);
  }
  .email-recipients__btn-list {
    width: var(--chakra-sizes-xs);
    margin-left: var(--chakra-space-5);
  }
  .email-recipients__on-hover-email-list {
    width: var(--chakra-sizes-xs);
    height: var(--chakra-sizes-3xs);
    overflow: scroll;
  }
  .email-recipients__on-hover-email-icon {
    cursor: pointer;
    color: var(--chakra-colors-primary-900);
  }
  .email-recipients__email-edit-recipient-btn.ant-btn {
    border: none;
  }
  .email-recipients__individual-email-ids {
    width: var(--chakra-sizes-xs);
    padding-top: var(--chakra-space-4);
  }
}
