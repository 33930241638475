.invoice-results {
  font-family: Helvetica;

  .invoice-results__text {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    color: #545454;
    padding-bottom: 18px;
  }

  .react-grid-Cell {
    border-right: 1px solid transparent;
  }
}

.rdg {
  .rdg-cell {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  .rdg-cell[role='gridcell'] {
    p {
      height: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    label {
      margin-bottom: 0px;
    }
  }

  .rdg-cell[role='columnheader'] {
    border-right: 1px solid var(--border-color);
  }
}
