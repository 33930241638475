@font-face {
  font-family: 'FMGIcons';
  src: url('./FMGIcons.ttf') format('truetype'), url('./FMGIcons.eot') format('embedded-opentype'),
    url('./FMGIcons.woff') format('woff');
}

i.fmg-icon {
  font-family: 'FMGIcons' !important;
  font-size: 18px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

i.fmg-icon.fmg-icon-claims:before {
  content: '\ea01';
}
