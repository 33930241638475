.chartWrapper {
  width: 100%;
  height: 300px;
  margin-bottom: 20px;
}

.chartWrapper svg {
  width: 100%;
  height: 100%;
}

.xAxisTick {
  font-size: 12px;
  line-height: 17px;
  text-anchor: middle;
}

.xAxisLine {
  stroke-width: 2px;
  stroke: #0b1435;
}

.yAxisTick {
  font-size: 14px;
  color: #0b1435;
  text-anchor: end;
  dominant-baseline: middle;
}

.yAxisTickLine {
  stroke: rgba(11, 20, 53, 0.1);
  stroke-width: 1px;
}

.left {
}

.right {
  text-align: right;
}

.hoverLabel {
  font-size: 14px;
  font-weight: normal;
  text-anchor: middle;
}

.hoverLabelValue {
  font-weight: bold;
}

.line {
  stroke-width: 2px;
  fill: none;
}
