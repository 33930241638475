.grinder-table-container {
  .rdtPicker {
    min-width: 260px;
  }

  .anticon.grinder-table__ordered-status-true {
    color: #52c41a;
    padding-right: 3.5px;
    font-size: 20px;
  }

  .anticon.grinder-table__ordered-status-false {
    display: none !important;
  }

  .grinder-table-first-col {
    margin-left: 5px;
  }

  .grinder-table__input_control {
    width: 125px;
    height: 28px;
    color: black;
    border-radius: 8px;
    box-shadow: 0px 2px 3px 0px #bfc5d2 inset;
  }

  .grinder-table__datepicker{
    height: 28px;
    color: black;
    border-radius: 8px;
    box-shadow: 0px 2px 3px 0px #bfc5d2 inset;
  }

  .ant-table-thead > tr > th {
    background-color: #edf1f7 !important;
  }

  .ant-table-column-title {
    font-family: Helvetica !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.57 !important;
    letter-spacing: normal !important;
    text-align: right !important;
    color: #4a4a4a !important;
  }

  .ant-picker.ant-picker-default.grinder-table__input_control.ant-picker-disabled {
    color: black !important;
  }

  #pickup_date_picker,
  #delivery_date_picker,
  .ant-picker-input input:disabled,
  .ant-picker-input span {
    color: black;
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled span {
    color: black;
  }

  .ant-select-selection-item {
    color: black;
  }

  .grinder-table__select-dropdown {
    width: 190px;
  }
}

// custom class grinder-table__select-dropdown, grinder-table__input_control available
// but css isn't picking up
.ant-select-dropdown {
  width: fit-content !important;
}

.ant-calendar-footer,
.ant-calendar-input-wrap {
  display: none;
}

.ant-picker-dropdown {
  z-index: 9999 !important;
}

.ant-popover-message > .anticon {
  top: 20.0005px !important;
}

.ant-popover-message-title {
  width: 250px;
}
