$c-button-primary: #63729d;
$c-button-secondary: #ec864f;
$c-text-white: #fff;

.confirmation-buttons {
  h4 {
    font-family: Helvetica;
    font-size: 20px;
    color: #36404a;
    margin-bottom: 17px;
    margin-top: 50px;
  }

  .confirmation-buttons__button {
    border-radius: 2px;
    color: $c-text-white;
    width: 120px;
    text-align: center;
    margin: 0 10px;
    border: 0px solid transparent;
  }

  .confirmation-buttons__button--primary {
    background-color: $c-button-primary;
  }

  .confirmation-buttons__button--secondary {
    background-color: $c-button-secondary;
  }
}
