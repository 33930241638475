.coldstore-email-modal {
  .coldstore-email-modal__warning {
    padding-top: 25px;
  }

  .ant-collapse-content {
    overflow: initial;
  }

  .ant-modal-footer {
    border: none;
  }

  .ant-modal-header {
    height: 45px;
    border-radius: 2px;
    background-color: #f7f9fc;

    .ant-modal-title {
      height: 28px;
      font-family: Helvetica;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.1;
      letter-spacing: normal;
      text-align: center;
      color: #36404a;
    }
  }
}
