.live-cl-filters .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 13px;
  color: #828282;
}

.live-cls__cl-table td {
  color: #828282;
  font-weight: 500;
}

.live-cls__cl-table th {
  font-size: 10px;
  font-weight: 500;
}
