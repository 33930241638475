.entity-table {
  overflow: scroll;
  border-width: 1px;
  border-radius: 4px;
  margin-top: 15px !important;
  .ant-table-column-sorter {
    display: none;
  }

  .ant-table-cell {
    border-width: 0px;
    font-size: 12px;
  }
  .ant-table-thead .ant-table-cell {
    font-family: Poppins, sans-serif;
    font-size: 11px;
    font-weight: 700;
    background-color: #f7fafc;
    border-bottom-width: 1px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    content: none;
  }

  .entity-table-row,
  .ant-table-column-sort {
    background: #ffffff;
  }
}
