.grinder-release {
  .grinder-release__button {
    margin-left: var(--chakra-space-4) !important;
  }

  .grinder_release-button-primary {
    background-color: var(--chakra-colors-primary-700) !important;
    width: auto;
    color: var(--chakra-colors-white) !important;
  }

  button:disabled {
    background-color: var(--chakra-colors-gray-300) !important;
    color: var(--chakra-colors-white) !important;
    font-size: var(--chakra-fontSizes-md);
    text-align: center;
  }
}
