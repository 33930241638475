.theme-json-editor__active {
  .ace_content {
    cursor: text;
  }
}
.theme-json-editor__disabled {
  .ace_content {
    cursor: not-allowed;
  }
}
