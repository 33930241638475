.BubbleChart_mainChartContainer {
  display: flex;
}

.BubbleChart_chartContainer {
  width: 100%;
  position: relative;
}

.BubbleChart_svg {
  height: 100%;
  width: 100%;
}
