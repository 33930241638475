#right-angle__icon {
  width: 12px;
  height: 12px;
  position: relative;
}

#right-angle__icon::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

#right-angle__icon::after {
  border-width: 6px;
  border-right-color: var(--chakra-colors-accent-one-default, #fd5c38);
  border-bottom-color: var(--chakra-colors-accent-one-default, #fd5c38);
}
