.coldstore-inventory-filters {
  .onTop {
    z-index: 10001 !important;
  }
}

.filter-input {
  border-radius: 8px;
  border-color: var(--chakra-colors-neutral-5);
}
/* Inventory filter and table */
.css-3jmgvu .chakra-input{
  border-color: #ccc;
  border-radius: 4px;
  font-weight: initial;
  color: hsl(0, 0%, 50%);
}
.css-3jmgvu .chakra-input::placeholder{
  color: hsl(0, 0%, 50%);
  font-weight: initial;
}
.css-3jmgvu .chakra-input:hover {
  border-color: hsl(0, 0%, 70%);
}
.form-control.filter-input.font-normal{
  font-weight: normal !important;
}
.css-w2j76p::placeholder{
  color: hsl(0, 0%, 50%);
}