.existingItem {
  opacity: 0.7;
  cursor: pointer;
  background-color: #fafafa;
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.existingItem:hover {
  opacity: 1;
}

.addNewItem {
  opacity: 0.1;
  cursor: pointer;
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.addNewItem:hover {
  opacity: 1;
}
