.PackerPlantHistory_totalSuppliers {
  font-size: 22px;
}

.PackerPlantHistory_totalSuppliers span {
  font-size: 30px;
  font-weight: bolder;
}

.PackerPlantHistory_divider {
  display: inline-block;
  margin: 0 6px;
}

.PackerPlantHistory_newBadge {
  border: 1px solid rgba(0, 0, 0, 0.6);
  display: inline-block;
  padding: 2px 6px;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 3px;
}

.PackerPlantHistory_tables {
  display: flex;
}

.PackerPlantHistory_table {
  width: 100%;
}

.PackerPlantHistory_table:first-child {
  margin-right: 10px;
}

.PackerPlantHistory_table:last-child {
  margin-left: 10px;
}

.PackerPlantHistory_tableRow {
  display: flex;
  align-items: center;
  padding: 5px 0 5px 0;
  position: relative;
}

.PackerPlantHistory_tableRow[data-vertical='true'] {
  flex-direction: column;
}

.PackerPlantHistory_tableRow[data-vertical='true']::before {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #0b1435;
  content: '';
  position: absolute;
  left: 0;
  top: 14px;
}

.PackerPlantHistory_title {
  border-bottom: 1px solid #d2d2d2;
  text-transform: uppercase;
  font-size: 16px;
}

.PackerPlantHistory_title span {
  color: #0b1435;
  opacity: 0.6;
}

.PackerPlantHistory_progress {
  display: flex;
  align-items: center;
}

.PackerPlantHistory_step {
  height: 3px;
  width: 5px;
  background-color: rgba(11, 20, 53, 0.1);
  margin: 0 2px;
}

.PackerPlantHistory_step[data-completed='true'] {
  background-color: rgba(11, 20, 53, 1);
}

.PackerPlantHistory_achievementText {
  font-family: Roboto, sans-serif;
  display: block;
  color: rgba(11, 20, 53, 0.6);
}

.PackerPlantHistory_achievementCategory {
  font-family: Roboto, sans-serif;
  display: block;
}

.PackerPlantHistory_toggleMore {
  display: block;
  font-family: Roboto, sans-serif;
  padding-left: 20px;
  margin-top: 10px;
  color: rgba(11, 20, 53, 0.6);
  cursor: pointer;
}
