.source,
.schedule {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sourceVis {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-left: 8px;
}

.sourceVisBlock {
  height: 12px;
  border: 1px solid white;
}

.scheduleVis {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: flex-end;
  padding: 0 4px;
  border-bottom: 1px solid #f0f0f0;
  margin-right: 8px;
}

.scheduleVisBlock {
  background: red;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.selectedRow {
  background: #f0f0f0;
}

.entity {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  display: inline-block;
}

/* ------ */

.table {
  width: 33%;
}

.tableRow {
  display: flex;
  padding: 5px 10px 5px 0;
}

.title {
  border-bottom: 1px solid #d2d2d2;
  text-transform: uppercase;
  font-size: 15px;
  color: #d2d2d2;
  white-space: nowrap;
  overflow: hidden;
}
