.price-breakdown {
  width: 300px;
  font-size: 12px;
  .price-breakdown__category {
    margin-bottom: 20px;
  }

  .price-breakdown__text {
    padding-bottom: 3px;
    border-bottom: 1px solid var(--chakra-colors-neutral-6);
    margin-bottom: 7px;
  }

  .price-breakdown__text,
  .price-breakdown__header {
    font-weight: bold;
  }

  .price-breakdown__items {
  }

  .price-breakdown__item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .price-breakdown__cell,
  .price-breakdown__header {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}
