$c-increment-buton: var(--chakra-colors-secondary-900);
$c-border: var(--chakra-colors-gray-300);
$c-border-error: var(--chakra-colors-red-900);

.number-spinner-input {
  i {
    font-size: 11px;
    color: $c-increment-buton;
  }

  .number-spinner-input__input-group {
    position: relative;
    display: flex;
    background: white;
    border-radius: 2px;
    border: solid 1px $c-border;
    min-width: 110px;
  }

  .number-spinner-input__input-group--error {
    border-color: $c-border-error;
  }

  .number-spinner-input__input {
    border: 0px solid transparent;
    height: 35px;
    padding: 0;
    text-align: center;
  }

  .number-spinner-input__control {
    height: 35px;
  }

  .number-spinner-input__control,
  input,
  button {
    display: block;
    width: 100%;
    border-radius: 0;
  }
}
