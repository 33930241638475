.rowContainer {
  position: relative;
  margin-left: 40px;
}

.hedgedData {
  position: absolute;
  height: 20px;
  text-align: center;
}

.YAxis {
  height: 10px;
  width: calc(100% - 40px);
  margin-left: 40px;
}

.tableTitle {
  padding-left: 10px;
  font-size: 10px;
}