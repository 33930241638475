.coldstore-inventory-table {
  .m-table.m-table--head-separator-danger tr:hover {
    box-shadow: var(--chakra-shadows-none);
  }

  .m-table.m-table--head-separator-danger thead th {
    cursor: auto;
    text-align: left;
  }

  .m-table.m-table--head-separator-danger thead th:first-child,
  .m-table.m-table--head-separator-danger tbody td:first-child {
    padding-left: calc(var(--chakra-space-10) + var(--chakra-space-1\.5));
  }

  .m-table.m-table--head-separator-danger thead th .sort-icon {
    position: static;
  }

  .m-table.m-table--head-separator-danger thead th {
    padding-right: var(--chakra-space-5);
  }

  .coldstore-inventory-table__th-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .coldstore-inventory-table__th-content--min {
    min-width: 90px;
  }
}
th.css-cg4lve{letter-spacing:inherit;}
.select-field{border-color: #fff;}
