.Audit_auditSubtitle {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #fc5d36;
  margin: 30px 0 20px;
  display: block;
}

.Audit_auditSubtitle span {
  color: #0b1435;
  opacity: 0.6;
}

.Audit_performedTable .Audit_header,
.Audit_performedTable .Audit_row,
.Audit_upcomingTable .Audit_header,
.Audit_upcomingTable .Audit_row {
  width: 100%;
  display: flex;
}

.Audit_body {
  border-top: 1px solid rgba(210, 210, 210, 0.5);
  border-bottom: 1px solid rgba(210, 210, 210, 0.5);
}

.Audit_title,
.Audit_subtitle {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #0b1435;
}

.Audit_subtitle div {
  display: flex;
  align-items: center;
  padding: 0 8px 4px;
}

.Audit_subtitle svg {
  width: 16px;
  margin-left: 8px;
}

.Audit_title {
  opacity: 0.6;
  padding: 4px 8px;
}

.Audit_subtitle {
  color: #0b143566;
}

.Audit_subtitle,
.Audit_cell {
  display: flex;
}

.Audit_cell div {
  padding: 4px 8px;
}

.Audit_upcomingHeader {
  color: #0b1435;
}

.Audit_upcomingHeader span {
  opacity: 0.6;
}

.Audit_greenBadge,
.Audit_yellowBadge,
.Audit_amberBadge,
.Audit_redBadge,
.Audit_greyBadge {
  box-sizing: border-box;
  border-radius: 2px;
  font-family: 'Poppins', 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 2px 4px !important;
}

.Audit_greenBadge {
  border: 1px solid #09afa3;
  color: #09afa3;
}

.Audit_yellowBadge {
  border: 1px solid #cda800;
  color: #cda800;
}

.Audit_amberBadge {
  border: 1px solid #ff7f00;
  color: #ff7f00;
}

.Audit_redBadge {
  border: 1px solid #ff2c1b;
  color: #ff2c1b;
}

.Audit_greyBadge {
  border: 1px solid #6d7286;
  color: #6d7286;
}
