.tableRow {
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 0;
}

.title {
  border-bottom: 1px solid #d2d2d2;
  text-transform: uppercase;
  font-size: 16px;
  color: rgba(11, 20, 53, 0.6);
  display: flex;
  align-items: center;
}

.title svg {
  width: 24px;
  margin-right: 5px;
}

.claimsAll {
  display: flex;
  width: 100%;
}

.claims {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.count {
  font-size: 26px;
  font-weight: bold;
}

.scheduleVis {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: flex-end;
  padding: 0 4px;
  border-bottom: 1px solid #f0f0f0;
  margin-right: 8px;
}

.scheduleVisBlock {
  background: red;
  border-left: 1px solid white;
  border-right: 1px solid white;
}
