.index {
  background: #fafafa;
  padding: 2px 4px;
  display: inline;
  margin-left: 1rem;
  margin-right: 0.5rem;
  font-weight: bold;
}

.paragraph {
  font-size: 0.75rem;
  text-align: left;
  flex-grow: 1;
  margin-left: 1rem;
}

.timestamp {
  font-weight: bold;
}
