.invoice-email-modal {
  font-family: Helvetica;
  width: calc(100vw - 30px) !important;

  .invoice-email-modal__error {
    text-align: center;
    margin-bottom: 20px;
  }

  .invoice-email-modal__text {
    font-size: 14px;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 18px;
  }

  h3.invoice-email-modal__subheader {
    font-size: 16px;
    font-weight: bold;
    color: #36404a;
    margin-top: 18px;
    margin-bottom: 24px;
  }

  h4.invoice-email-modal__subheader {
    font-size: 14px;
    font-weight: bold;
    color: #434343;
    margin-top: 26px;
    margin-bottom: 16px;
  }

  @media (min-width: 769px) {
    h4.invoice-email-modal__subheader {
      margin-top: 12px;
    }
  }

  .invoice-email-modal__subject {
    margin-bottom: 20px;
  }

  .invoice-email-modal__body {
    margin-bottom: 28px;
  }

  .invoice-email-modal__label {
    font-size: 13px;
    font-weight: bold;
    color: #434343;
  }

  .invoice-email-modal__link {
    font-size: 12px;
    line-height: 1.17;
    color: var(--chakra-colors-actionPrimary-blueButton);
    padding-left: 0px;
    margin-top: 18px;
  }

  .invoice-email-modal__attachments {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .invoice-email-modal__attachment {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;
    background-color: var(--chakra-colors-neutral-5);
    border: 1px solid var(--chakra-colors-neutral-5);
    color: var(--chakra-colors-neutral-white);
    font-weight: 500;
    height: 35px;
    min-width: 100px;
    font-size: 13px;
    margin-right: 10px;
    margin-bottom: 10px;

    i.anticon.anticon-loading {
      font-size: 13px;
      position: relative;
      left: 8px;
      bottom: 0px;
      color: var(--chakra-colors-neutral-white);
    }
  }

  .invoice-email-modal__attachment--loading {
    cursor: not-allowed;
    background-color: var(--chakra-colors-neutral-white);
  }

  .invoice-email-modal__totals {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .invoice-email-modal__total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    border-radius: 2px;
    background-color: var(--chakra-colors-neutral-9);
    padding: 15px 20px;
    margin-right: 15px;
    margin-bottom: 8px;
  }

  .invoice-email-modal__total-value {
    color: #4a4a4a;
    padding-right: 20px;
    font-size: 20px;

    span {
      font-size: 16px;
      color: #4a4a4a;
      padding-left: 7px;
    }
  }

  @media (min-width: 1500px) {
    .invoice-email-modal__total-value {
      font-size: 20px;
    }
  }

  .invoice-email-modal__total-label {
    font-size: 12px;
    color: var(--chakra-colors-actionNeutral-darkGray);
  }

  .ant-modal {
    // top: 50px;
  }

  .ant-modal-header {
    background-color: var(--chakra-colors-neutral-9);
    font-size: 20px;
    color: #36404a;
    text-align: center;
  }

  .ant-modal-body {
    padding-left: 40px;
    padding-right: 40px;
  }

  .ant-modal-footer {
    border-top: 1px solid transparent;
    .ant-btn {
      padding-right: 40px;
      padding-left: 40px;
      margin: 7px;
      margin-bottom: 20px;
      float: right;
    }

    .ant-btn-primary {
      float: none;
    }
  }
}

@media (min-width: 994px) {
  .invoice-email-modal.ant-modal {
    width: calc(100vw - 150px) !important;
    margin-left: 100px;
    margin-right: 50px;
  }
}
