.edit-international-order {
  .edit-international-order__header {
    margin-top: 20px;
  }

  .edit-international-order__label {
    // margin-top: 4px;
  }

  hr {
    margin-bottom: 15px;
  }

  .edit-international-order__heading {
    font-weight: bold;
    font-size: 16px;
  }

  .edit-international-order__text {
    font-size: 14px;
    padding-left: 13px;
  }

  .edit-international-order__div--spaced {
    margin-left: 10px;
    padding-left: 3px;
  }

  .edit-international-order__div--top-margin {
    margin-top: 20px;
  }

  .edit-international-order__div--bottom-margin {
    margin-bottom: 20px;
  }

  .edit-international-order__div--yy-margin {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .edit-international-order__header--top-padding {
    padding-top: 12px;
  }

  .edit-international-order__highlighted {
    background-color: #ebeced;
  }

  .edit-international-order__section {
    margin-top: 30px;
    background-color: #ebeced;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .css-r447wv-singleValue {
    color: black !important;
    opacity: 1 !important;
    font-weight: bold !important;


  }

  .css-102ttgf-singleValue {
    color: black !important;
    opacity: 1 !important;
    font-weight: bold !important;

  }

  .chakra-input {
    color: black !important;
    opacity: 1 !important;
    font-weight: bold !important;



  }

  .form-control.m-input {
    color: black !important;
    opacity: 1 !important;
    font-weight: bold !important;


  }

  .form-control {
    color: black !important;
    opacity: 1 !important;
    font-weight: bold !important;


  }

  .chakra-input:disabled {
    opacity: 1 !important;
  }

  .tab-panel {
    margin-top: 35px;
    border-radius: 15px;
    background: #FAFAFA;
    padding: 37px 35px;
    /* Shadow/md */
    box-shadow: 0px 2px 4px -1px rgba(45, 55, 72, 0.06), 0px 4px 6px -1px rgba(45, 55, 72, 0.10);
  }
}