.QualityDetails_chartTitle {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.QualityDetails_chartTotal span {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #0b1435;
}

.QualityDetails_chartTotal strong {
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  letter-spacing: 0.03em;
  color: #000000;
}
