.ssr-config {
  font-family: Helvetica;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  max-width: 90vw;

  .breadcrumbs .breadcrumbs__crumb--first {
    background-image: none;
  }

  .ssr-config__info {
    border: 1px solid grey;
    left: 89px;
    padding: 25px;
  }

  .ssr-config__info-align {
    margin-left: 10px;
    display: flex;
  }
  .ssr-config__info-header {
    font-size: 16px;
    font-weight: bold;
    max-width: 20%;
  }
  .ssr-config__info-desc {
    margin-left: 20px;
    max-width: 80%;
  }

  .ssr-config__btn-cancel {
    margin-left: 15px;
    border-color: #1890ff;
    color: #1890ff;
  }
  .ssr-config__delete-confirm-div {
    margin-bottom: 10px;
    text-align: center;
  }
  .Select-input > input {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .report-results {
    max-width: 90vw;
  }
}
