$c-text-gray: #8f9bb3;
$c-text-dark-gray: #4a4a4a;
$c-header-gray: #36404a;

.confirmation-details {
  padding: 24px 20px;

  .confirmation-details__data-line {
    display: flex;
    font-family: Helvetica;
    font-size: 14px;
  }

  .confirmation-details__data-label {
    color: $c-text-gray;
    width: 100px;
  }

  .confirmation-details__data-label--large {
    width: 110px;
  }

  .confirmation-details__data-value {
    color: $c-text-dark-gray;
  }

  .confirmation-details__header {
    font-family: Helvetica;
    font-size: 20px;
    color: $c-header-gray;
    padding-bottom: 15px;
  }
}
