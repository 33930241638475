.filterRow {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.filterItem {
  margin-right: 20px;
  min-width: 120px;
}
