.invoice-cart {
  font-family: Helvetica;

  .invoice-cart__text {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    color: #545454;
    padding-bottom: 18px;
  }

  .invoice-cart__link {
    padding-left: 0px;
  }

  .react-grid-Cell {
    border-right: 1px solid transparent;
  }

  input.editor-main.form-control,
  select.editor-main {
    height: 35px !important;
  }

  // TODO - Was not able to figure out why
  // readonly attribute is being set on input,
  // So disabling styling for now
  .rdg-editor-container input.editor-main[readonly].ant-calendar-picker-input.ant-input {
    height: 32px !important;
    cursor: pointer;
    background-color: transparent;
  }

  .grinder-delivered-price {
    text-decoration: underline;
    cursor: pointer;
  }
}
