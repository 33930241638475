.voyage-card {
  .voyage-card__col {
    width: auto;
    margin-bottom: 15px;
  }

  .voyage-card__label-heading {
    margin-top: 5px;
    border-bottom: 1.25px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 13px;
    max-width: 48%;
  }

  .react-autosuggest__input {
    width: 160px;
  }
}
