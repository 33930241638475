.coldstore-lot-numbers__container {
   // position: absolute;

    .coldstore-lot-numbers__wrapper {
        display: flex;
    }

    .coldstore-lot-numbers__input {
        width: 80%;
        margin-top: 7px;
    }

    .coldstore-lot-numbers__icon {
        margin: 10px 0 0 7px;
    }
}