//
//
// 0. Foundational Variables
// 1. Navigation Variables
// 2. Card Variables


// 0. Colors
$primary-color: #FD5C38;


// 1. Navigation Variables
$header-height: 85px;
$header-background-color: white;
$header-text-color: #232323;
$logo-height: $header-height * .7;

$nav-background: #0B1435;
$nav-text: white;
$nav-selected: $primary-color;
$nav-item-radius: 4px;
$nav-side-padding: 20px;
$nav-icon-button-size: 36px;
$nav-closed-width: $nav-icon-button-size + $nav-side-padding * 2;
$nav-open-width: $nav-closed-width + 220px;
$nav-scrollbar-width: 1em;

// 2. Card Styles
$card-background: #FAFAFA;
$card-radius: 8px;
$card-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
$card-padding: 16px;
$card-title-font-size: 15px;
