@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,700');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');


@font-face {
  font-family: 'Soleil';
  src: url('./assets/fonts/SoleilBold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./assets/fonts/Helvetica-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url("./assets/fonts/Helvetica.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* cfc theme changes */
/* style */
.btn-success,
.btn-success:hover,
.btn-primary,
.btn-primary:hover {
  border-color: #63729d;
  background-color: #63729d;
}

.btn-success-confirmation,
.btn-success-confirmation:hover {
  border-color: #19ab27;
  background-color: #19ab27;
  color: #fff;
}

.btn-edit-popup-save {
  background-color: #63729d;
  margin-left: 95%;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: auto;
}

.edit-popup-header {
  margin-top: 18px;
  margin-left: 25px;
}

.btn-edit-popup-done {
  background-color: #63729d;
  margin-left: 95%;
  margin-top: 50px;
  margin-right: 20px;
  margin-bottom: 300px;
}

.input.edit.popup {
  padding-right: 10px;
}

.btn-info,
.btn-info:hover {
  background-color: #ec864f;
  border-color: #ec864f;
}

.btn-danger,
.btn-danger:hover {
  background-color: #eb6467;
  border-color: #eb6467;
}

.table .table-blue-box {
  color: #987200;
  background-color: #fef8e5;
  border-color: #fed24c;
}

.modal {
  overflow: scroll;
}

.width-100 {
  width: 120px;
}

.width-200 {
  width: 200px;
}

.m-footer--push.m-aside-left--enabled:not(.m-footer--fixed) .m-aside-right,
.m-footer--push.m-aside-left--enabled:not(.m-footer--fixed) .m-wrapper {
  margin-bottom: 0px;
}

.wall-mode {
  position: absolute;
  right: 0;
  z-index: 1000;
  margin: 10px 25px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* TODO - modify this .main-body style rule to get full width panes,
 * but some screens are negatively affected by it due to inconsistent
 * handling of screens/tabs. Some examples are daily tasks, the ops page,
 * and the cold store screens.
 */
.main-body {
  width: 96%;
}

.m-body .m-wrapper {
  overflow: visible;
}

.m-header--fixed .m-body {
  padding-top: 0px !important;
}

.error-msg button {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  margin-top: -10px;
  margin-right: -8px;
  background: rgba(0, 0, 0, 0.3);
  padding: 0px 3px;
  border-radius: 20px;
}

.m-body .m-content {
  padding: 0px;
}

/* Reset default boostrap styles
 * to fix UI bug with jumpy scrollbars
 */
.m-content.container-fluid > .row {
  margin-left: 0;
  margin-right: 0;
}

.m-content.container-fluid > .row > .col-xl-12 {
  padding-left: 0;
  padding-right: 0;
}
/* Done resetting */

.m-portlet.m-portlet--full-height .m-portlet__body {
  height: auto;
}

.m-portlet {
  background: none;
  box-shadow: none;
}

.m-portlet__head {
  background: white;
  height: auto !important;
}

.m-portlet__head h3 {
  padding: 20px 0px;
}

.m-portlet__body {
  background: white;
  margin: 10px;
  padding: 0px !important;
}

.m-portlet .m-portlet__head .m-portlet__head-tools {
  display: block;
}

.restrict-height .Select-control .Select-multi-value-wrapper {
  max-height: 100px;
  width: 100%;
  overflow-y: scroll;
}

.admin-tabs {
  /*float:right;*/
  text-transform: capitalize;
  cursor: pointer;
  margin-top: 10px !important;
  border-bottom: none !important;
}

.admin-tabs .nav-item.active .nav-link {
  font-size: 19px;
  margin-top: -5px;
  color: #333;
  border-bottom: 3px var(--chakra-colors-secondary-800) solid !important;
}

.admin-tabs .nav-item:hover .nav-link {
  border-bottom: 3px var(--chakra-colors-secondary-800) solid !important;
}

/* Fixes a UI bug with horizontal sizing changing depending on border.
 * The issue would be that the scroll bar would jump in and out of existence
 * based on tab hover states.
 */
.admin-tabs .nav-item .nav-link {
  border-bottom: 3px transparent solid;
}

.dot-tabs {
  margin-top: 15px;
  border-bottom: none;
}

.dot-tabs i {
  font-size: 20px !important;
}

.dot-tabs .btn p {
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
}

.dot-tabs .btn.dropdown-toggle:after,
.dot-tabs .nav-link.dropdown-toggle:after {
  content: none;
}

/* table modifications */
.m-table.m-table--head-separator-danger thead th {
  border: none;
  background: #f3f4f8;
  text-transform: uppercase;
  color: #919194;
  vertical-align: middle;
  cursor: pointer;
  padding-right: 25px;
  text-align: center;
  position: relative;
}

.m-table.m-table--head-separator-danger thead th .sort-icon {
  position: absolute;
  margin-left: 5px;
  margin-top: 2px;
  margin-right: 2px;
}

.m-table.m-table--head-separator-danger thead th:first-child,
.m-table.m-table--head-separator-danger tbody td:first-child {
  padding-left: 20px;
  display: table-cell;
  vertical-align: middle;
}

.m-table.m-table--head-separator-danger tbody .req-field:first-child:not([rowspan]) {
  padding-left: 10px !important;
}

.m-table.m-table--head-separator-danger {
  border: 1px rgba(255, 255, 255, 0.6) solid;
}

.m-table.m-table--head-separator-danger tr:hover {
  box-shadow: 2px 2px 3px #ccc;
  background: #f3f4f8;
}

/* sidebar */
#m_aside_left {
  position: fixed;
  z-index: 10000;
  height: 100%;
}

#m_aside_left::-webkit-scrollbar {
  display: none;
}

#m_aside_left .main-logo {
  margin-left: 5px;
  width: 30px;
}

#m_aside_left .main-logo img {
  width: 100%;
}

.m-aside-left.m-aside-left--skin-dark {
  background-color: #2c333d;
  width: 50px;
}

#m_ver_menu .top-menu {
  padding: 5px;
  border-bottom: #2a3546 2px solid;
}

#m_ver_menu .top-menu h1 {
  font-size: 25px;
  color: #adb9c9;
}

#m_ver_menu .top-menu i {
  font-size: 20px;
  color: #adb9c9;
  margin-top: 5px;
}

#m_ver_menu > div:not(.top-menu) {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
}

#m_ver_menu .icon-item {
  width: 100%;
  font-size: 13px;
  text-align: center;
  border: #565b63 2px solid;
  background: #2c333d;
  border-radius: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

#m_ver_menu .icon-item i {
  font-size: 25px;
}

#m_ver_menu .icon-item-- {
  background: #414750;
  cursor: pointer;
  border: #565b63 2px solid;
}

#m_ver_menu .icon-item:hover {
  background: #414750;
  cursor: pointer;
  border: #565b63 2px solid;
}

#m_ver_menu a.icon-item {
  color: #7f8b9d;
}

#m_ver_menu a.icon-item--active {
  color: #dee0e4;
  text-decoration: none;
}

#m_ver_menu a.icon-item:hover {
  color: #dee0e4;
  text-decoration: none;
}

#m_ver_menu .admin-menu {
  /* padding: 20px 15px 15px 15px; */
}

#m_ver_menu hr {
  border-top: #2a3546 2px solid;
  width: 87%;
  margin-top: 5px;
}

#m_ver_menu .admin-menu h4 {
  font-size: 14px;
  margin-bottom: 25px;
  text-transform: uppercase;
  color: #2c333d;
  font-weight: normal;
}

#m_ver_menu .admin-menu p a {
  font-size: 14px;
  color: #adb9c9;
}

#m_ver_menu .admin-menu p .active {
  text-decoration: underline;
}

/* dashboards */
.markets-table {
  width: 100%;
  font-size: 11px;
}

.markets-table thead {
  line-height: 4;
}

.markets-table tbody {
  line-height: 1;
}

.markets-table td {
  padding-bottom: 15px;
}

.markets-table span {
  padding: 3px;
}

.markets-table .markets-red {
  background: rgba(255, 0, 0, 0.5);
  border-radius: 10px;
  border: rgba(255, 0, 0, 0.8) thin solid;
}

.markets-table .markets-yellow {
  background: rgba(255, 217, 102, 0.5);
  border-radius: 10px;
  border: rgba(255, 217, 102, 0.8) thin solid;
}

.markets-table .markets-green {
  background: rgba(0, 128, 0, 0.5);
  border-radius: 10px;
  border: rgba(0, 128, 0, 0.8) thin solid;
}

.kpi-summary-stats {
  padding-left: 0px;
  list-style-type: none;
}

.kpi-summary-stats-target {
  font-weight: bold;
}

.kpi-summary-stats li {
  display: inline-block;
  font-size: 12px;
  margin-right: 10px;
}

.kpi-summary-stats li span {
  font-size: 20px;
  color: green;
}

.kpi-table {
  width: 100%;
}

.kpi-table span {
  font-size: 20px;
}

.forecast-accuracy-table {
  width: 100%;
  margin-top: 50px;
}

.forecast-accuracy-table span {
  font-size: 20px;
}

.rotated-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* login page */
.m-login.m-login--1 .m-login__wrapper {
  padding: 0;
}

.add-new-button {
  float: right;
  margin-top: 15px;
}

thead tr th,
.m-portlet__head-text,
.modal-body .form-control-label {
  text-transform: capitalize;
}

.modal-full.modal-dialog {
  width: 97%;
  max-width: 97%;
}

.modal-md.modal-dialog {
  width: 90%;
  max-width: 90%;
}

.modal-mid.modal-dialog {
  width: 45%;
  max-width: 45%;
}

tbody tr.clickable {
  cursor: pointer;
}

.loading-graphic {
  text-align: center;
}

.loading-graphic img {
  width: 100px;
}

/* articles */
.demo-wrapper .demo-editor {
  min-height: 200px;
}

/* optimiser */

.slider-container-optimizer {
  width: 90%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.slider {
  display: inline-block;
  width: 76%;
  float: left;
}

.slider-amount {
  display: inline-block;
  width: 19%;
  float: right;
  margin-top: 12px;
}

.slider-amount > input {
  width: 34px;
  text-align: center;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  border-color: #f4f5f8;
  color: #6f727d;
  background-color: #f4f5f8;
}

.slider-container-optimizer .rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}

.input-columns {
  display: inline-block;
  width: 200px;
  white-space: normal;
}

.input-columns.column-0 {
  width: 280px;
}

.input-columns ul {
  padding: 0;
  list-style-type: none;
}

.input-columns .input-info {
  /*height: 22px;*/
  margin-bottom: 10px;
  width: 90%;
  text-align: right;
}

.block-column {
  display: block;
}

.output-columns li {
  width: 90%;
}

.output-columns {
  text-align: right;
}

.output-columns .form-control {
  font-size: 12px;
  text-align: right;
  padding: 2px;
}

.second-row .column-0 {
  display: inline-block;
}

/* map */
.map-legend {
  bottom: 0;
  z-index: 10;
  position: absolute;
  right: 0;
  margin-bottom: 30px;
  margin-right: 30px;
  background: rgba(255, 255, 255, 0.9);
  padding: 5px;
}

.weather-popup {
  max-width: 300px;
}

.refresh-alert {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 30px;
  margin-top: 70px;
}

.no-data-alert {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 30px;
  margin-top: 130px;
}

.map-overlay {
  z-index: 10;
  position: absolute;
  width: 250px;
  left: 0;
  top: 0;
  margin-top: 65px;
  margin-left: 20px;
}

.report-selector {
  z-index: 10000;
  position: absolute;
  /* width: 600px; */
  right: 0;
  top: 0;
  margin-top: 5px;
  margin-right: 30px;
}

.report-selector .rdt {
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 5px;
}

.report-selector .report-to-text {
  margin-top: 13px;
}

.map-loader {
  position: absolute;
  margin-top: 20px;
  width: 100px;
  margin-left: calc(50% - 50px);
  z-index: 1000;
}

.map-overlay .grinders-overlay,
.map-overlay .packer_plants-overlay,
.map-overlay .trucks-overlay,
.map-overlay .weather-overlay,
.map-overlay .dcs-overlay,
.map-overlay .selected-overlay {
  margin-bottom: 5px;
}

.map-overlay .overlay-header {
  background: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  padding: 2.5px 10px;
  cursor: pointer;
}

.map-overlay .overlay-collapse {
  background: rgba(255, 255, 255, 0.8);
  height: auto;
  max-height: 175px;
  overflow-y: scroll;
  padding: 5px 5px;
}

.map-overlay .ops-overlay .overlay-collapse {
  max-height: 400px;
}

.map-overlay .ops-overlay .overlay-collapse.wall-collapse {
  max-height: none;
}

.map-overlay .overlay-collapse.collapsed {
  height: 0px;
  visibility: hidden;
  padding: 0px;
}

/* admin */
.collapsing-icon {
  margin-left: 20px;
  font-size: 25px;
  transform: 1s;
}

.collapsing-icon.opened {
  transform: rotate(90deg);
}

.row-day-pickers input {
  padding: 0.65rem 0.5rem;
}

.modal {
  margin-left: 50px;
}

.modal-footer .popover {
  z-index: 100000;
  opacity: 1;
  border: thin solid #eee;
  position: absolute;
  top: auto;
  left: auto;
  width: 250px;
  margin-left: 80px;
  margin-top: -60px;
  padding-bottom: 7px;
  box-shadow: 2px 2px 10px grey;
}

.modal-footer .popover h3 {
  font-size: 15px;
  background: #eee;
  padding: 10px;
}

.modal-footer .popover p {
  padding: 0px 8px;
}

.table-blue-box {
  background-color: rgba(0, 126, 255, 0.08);
  border-radius: 2px;
  border: 1px solid #c2e0ff;
  border: 1px solid rgba(0, 126, 255, 0.24);
  color: #007eff;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1.4;
  margin-left: 5px;
  margin-top: 5px;
  padding: 5px;
  vertical-align: top;
}

.fixed-calendar-picker {
  position: fixed;
  box-shadow: 2px 2px 10px #ccc;
  bottom: 0;
  left: 265px;
  margin-bottom: 10px;
}

.fixed-calendar-picker .dropdown-menu > .dropdown-item,
.fixed-calendar-picker .dropdown-menu > li > a {
  padding: 5px;
}

.fixed-forecast-picker {
  box-shadow: 2px 2px 10px #ccc;
  position: absolute;
  z-index: 1000;
}

.fixed-forecast-picker .dropdown-close {
  font-size: 5px;
  position: absolute;
  right: 0;
  padding: 5px;
  border: thin #ccc solid;
  border-radius: 20px;
  margin-top: -15px;
  background: #eee;
  margin-right: -10px;
}

.fixed-forecast-picker .dropdown-close:hover {
  cursor: pointer;
  background: white;
}

.forecast-picker .popover {
  margin-left: -150px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  text-align: start;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.forecast-picker .popover .popover-title {
  margin: 0;
  font-weight: 300;
  padding: 8px 14px;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.forecast-picker .popover .popover-content {
  padding: 9px 14px;
}

.forecast-picker .popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25);
}

@media (min-width: 1676px) {
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.results-text {
  margin-top: 30px;
  margin-right: 3px;
  font-style: italic;
}

.main-tick {
  position: absolute;
  margin-top: 42%;
  margin-left: 3px;
  font-size: 17px;
  font-weight: bold;
}

.single-ingredient-page .error {
  border: red thin solid;
}

.packer-table .error {
  border: red thin solid;
}

.rdtPicker {
  min-width: 100%;
  width: auto;
}

.datestart-badge {
  margin-left: -34px;
  float: left;
  margin-top: -26px;
}

.dateend-badge {
  margin-left: -30px;
  float: left;
  margin-top: -26px;
}

.excel-dropzone {
  border: 4px dashed #2c333d;
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  font-weight: 500;
  font-size: 24px;
  margin: 20px;
  height: 100%;
  cursor: copy;
}

.excel-dropzone-active {
  background: #ccc;
  border: 4px dashed #2c333d;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  font-weight: 500;
  font-size: 24px;
  margin: 20px;
  height: 100%;
  cursor: copy;
}

.history-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.edit-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.order-results-table {
  font-size: 12px;
  text-transform: capitalize;
}

.order-results-table .date-range-search > div {
  display: flex;
}

.finalize-success-msg,
.plus-one {
  color: #34bfa3;
  font-weight: 400;
}

.plus-one {
  margin-left: 8px;
}

.finalize-div {
  align-items: center;
}

.table-checkbox {
  margin: 0 auto;
}



/* datepicker in table */
table .rdtPicker {
  width: auto;
}

/* KPI dashboard -- GrinderKPIDashboard.js */
.table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 95%;
}

.table-container {
  padding: 3rem 1rem;
  border-width: 1px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.table-container:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.table-container td {
  white-space: nowrap;
  overflow: hidden;
}

.fab-date-alert .flaticon-circle {
  float: right;
  margin-top: -25px;
  cursor: pointer;
  color: #333;
  margin-right: -30px;
}

.polog-full-table {
  overflow: auto;
}

.button-active-color {
  background-color: grey;
}

.edit-comment-input-box {
  min-height: 40px;
}

.orderLinesContainer > div > div > div:first-child {
  background-color: #f4f5f8;
  border-radius: 5px;
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;
}

.orderLinesContainer > div > div > div:nth-child(2) {
  margin-left: -8px;
  margin-right: -8px;
}

.verticalLineIndex {
  border: solid 1px grey;
  border-radius: 2px;
  margin-top: 25px;
}

.verticalLineIndex span {
  transform: rotate(-90deg);
  font-size: 1.25rem;
}

.orderLinesMainContainer > div:first-child {
  display: inline-block;
  width: 5%;
}

.orderLinesMainContainer > div:nth-child(2) {
  display: inline-block;
  width: 95%;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  padding: 0.65rem 1.25rem;
  font-size: 1rem;
  line-height: 1.25;
  color: white;
  border-radius: 3px;
  background-color: grey;
  display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: grey;
}

.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}

/* react toggle component styles*/

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128d15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}

/* Boostrap Toggle styles */
label.checkbox .toggle,
label.checkbox.inline .toggle {
  margin-left: -20px;
  margin-right: 5px;
}

.toggle {
  min-width: 40px;
  height: 20px;
  position: relative;
  overflow: hidden;
}

.toggle-group {
  position: absolute;
  width: 200%;
  top: 0;
  bottom: 0;
  left: 0;
  transition: left 0.35s;
  -webkit-transition: left 0.35s;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.toggle.off .toggle-group {
  left: -100%;
}

.toggle-on {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.toggle-off {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.toggle-handle {
  position: relative;
  margin: 0 auto;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100%;
  width: 0px;
  border-width: 0 1px;
}

.toggle-handle.btn-mini {
  top: -2px;
}

.toggle-handle.btn-xs {
  top: -2px;
}

.toggle.btn {
  min-width: 30px;
}

.toggle-on.btn {
  padding-right: 20px;
  padding-left: 0;
}

.toggle-off.btn {
  padding-left: 10px;
  padding-right: 0;
}

.toggle.btn-large {
  min-width: 40px;
}

.toggle-on.btn-large {
  padding-right: 35px;
}

.toggle-off.btn-large {
  padding-left: 35px;
}

.toggle.btn-lg {
  min-width: 40px;
}

.toggle-on.btn-lg {
  padding-right: 35px;
}

.toggle-off.btn-lg {
  padding-left: 35px;
}

.toggle.btn-small {
  min-width: 25px;
}

.toggle-on.btn-small {
  padding-right: 20px;
}

.toggle-off.btn-small {
  padding-left: 20px;
}

.toggle.btn-sm {
  min-width: 25px;
}

.toggle-on.btn-sm {
  padding-right: 20px;
}

.toggle-off.btn-sm {
  padding-left: 20px;
}

.toggle.btn-mini {
  min-width: 20px;
}

.toggle-on.btn-mini {
  padding-right: 12px;
}

.toggle-off.btn-mini {
  padding-left: 12px;
}

.toggle.btn-xs {
  min-width: 20px;
}

.toggle-on.btn-xs {
  padding-right: 12px;
}

.toggle-off.btn-xs {
  padding-left: 12px;
}

.slow .toggle-group {
  transition: left 0.7s;
  -webkit-transition: left 0.7s;
}

.fast .toggle-group {
  transition: left 0.1s;
  -webkit-transition: left 0.1s;
}

.quick .toggle-group {
  transition: none;
  -webkit-transition: none;
}

.toggle-on[disabled],
.toggle-on.disabled {
  cursor: not-allowed !important;
}

.toggle-off[disabled],
.toggle-on.disabled {
  cursor: not-allowed !important;
}

.primary-toggle,
.primary-toggle:hover {
  border-color: #19ab27 !important;
  background-color: #19ab27 !important;
  color: #fff;
}

.toggle.btn-md {
  border-color: #ebedf2;
  background-color: #ebedf2;
}

/* end Boostrap Toggle styles */

.react-autosuggest__input {
  width: 240px;
  height: 38px;
  padding: 10px 20px;
  font-size: 15px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input[disabled] {
  border-color: #f4f5f8;
  color: #b9bcc7;
  background-color: #f4f5f8;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 65px;
  width: 240px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.error {
  border: red thin solid !important;
}

.restrict-height-select-multi-value-wrapper {
  max-height: 100px;
  width: 200px;
  overflow-y: auto;
}


.disabled-link {
  pointer-events: none;
  color: grey;

}

.disabled-link span{
  color: grey;

  font-style: italic;
}

.disabled-link i {
  color: grey;
}

.document-dropzone {
  border: 2px dashed #2c333d;
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  font-weight: 500;
  font-size: 24px;
  margin: 20px;
  height: 150px;
  width: 300px;
  cursor: copy;
}

.document-dropzone--override {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.form-control.m-input {
  height: 40px;
}

.unread-count {
  display: inline-block;
  height:"37px";
  width:"37px";
  margin-top: 20px;
  font-weight: bold;
  line-height: 20px;
  border-radius: 50%;
  padding: 5px;
  background-color: red;
  accent-color: red;
  color: white;
}

.close-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: red;
  justify-content: flex-end; /* Align to the right */
  font-weight: bold;
}

.close-icon {
  margin-right: 410px;
  font-size: 20px;
  justify-content: flex-end; /* Align to the right */
}

.close-icon::before {
  content: 'X'; /* This is a simple "x" character, you can replace it with an actual icon */
}