.release-template {
  .ant-collapse-header {
    font-size: 20px;
    font-family: Helvetica, serif;
  }

  .sent-row td:first-child {
    border-left: 10px #f2f3f8 solid;
  }

  .new-row td:first-child {
    border-left: 10px darkgrey solid;
  }

  .actionable-mark {
    border-left: 10px #63729d solid;
  }

  .actionable-mark td:first-child {
    border-left: 10px #63729d solid;
    padding: 0 !important;
  }

  .ant-row.actionable-mark {
    margin-top: 0px !important;
    padding-top: 10px;
  }

  @-webkit-keyframes blink-2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes blink-2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  .actionable-blinking-mark {
    -webkit-animation: blink-2 0.9s infinite both;
    animation: blink-2 0.9s infinite both;
  }

  .actionable-blinking-mark td:first-child {
    border-left: 10px #63729d solid;
  }

  .table-calendar-release td {
    padding: 5px !important;
  }

  .table-calendar-release td:first-child {
    border-left: 0 !important;
  }

  .release-template__alert {
    max-width: 500px;
    margin-bottom: 25px;
  }
}
