.tip-card {
  b {
    font-weight: 500;
  }
}

.dropzone {
  padding: 0;
  border: none;
  .ant-upload-select {
    width: 100%;
  }
}
