.order-activity-logs {
  th,
  td {
    padding: 16px;
    white-space: nowrap;
    background-color: #fafafa
  }

  td {
    height: 80px;
    border-bottom: 1px solid transparent;
  }

  &__date-and-time-table {
    th {
      color: #000;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap;
      padding: 15px 10px;
    }

    td {
      position: relative;

      &::after {
        content: '';
        display: inline-block;
        height: 14px;
        width: 14px;
        background-color: #fff;
        border: 1px solid #cecece;
        border-radius: 50%;
        margin-left: 40px;
        margin-right: 30px;
        position: relative;
        z-index: 2;
      }

      &::before {
        content: '';
        display: inline-block;
        height: 100%;
        width: 1px;
        background: #cecece;
        position: absolute;
        left: calc(100% - 53px);
        z-index: 1;
      }
    }


    tr:last-child td::before {
      display: none;
    }
  }

  &__data-table {
    width: 100%;
  }
}
