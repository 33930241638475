.documents-tester {
    .tester-form {
        max-width: 350px;
        padding: 20px 16px;
        border-radius: 6px;
        border: 1px solid #F3F3F3;
        background: #F7FAFC;
        /* Shadow/md */
        box-shadow: 0px 4px 6px -1px rgba(45, 55, 72, 0.10), 0px 2px 4px -1px rgba(45, 55, 72, 0.06);
    }

    .tester-results {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px solid #EFEFEF;
        background: #FFF;
        /* Shadow/md */
        box-shadow: 0px 4px 6px -1px rgba(45, 55, 72, 0.10), 0px 2px 4px -1px rgba(45, 55, 72, 0.06);
        padding: 21px 20px;
        margin-left: 21px;
    }
}