/* login page */
.login-page-redesign {
  height: 100vh;
  width: 100vw;
  .underlay_bg,
  video {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
  }
  .underlay_bg {
    background: linear-gradient(
      180deg,
      var(--chakra-colors-loginBgGradient-900) 0%,
      var(--chakra-colors-loginBgGradient-50) 100%
    );
  }
  video {
    object-fit: cover;
  }
}
.login-page {
  min-height: 750px;
  min-width: 500px;
  .underlay_bg,
  video {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
  }
  .underlay_bg {
    background: linear-gradient(
      180deg,
      var(--chakra-colors-loginBgGradient-900) 0%,
      var(--chakra-colors-loginBgGradient-50) 100%
    );
  }
  video {
    object-fit: cover;
  }

  .login-container {
    .form-buttons-container {
      margin-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      /* background-color: #f7f9fb; */
    }

    .form-buttons-container .checkbox-container {
      display: flex;
    }

    .form-buttons-container .checkbox-container label {
      margin-left: 0.5rem;
    }

    .form-buttons-container .checkbox-container input {
      margin-top: 0.4rem;
      cursor: pointer;
    }

    #form-title {
      font-weight: 500 !important;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 24px;
    }

    .content .forget-password {
      margin-top: 0px;
    }

    #login-page-forget-form.forget-form {
      margin-top: 1.5rem;
      font-family: 'Source Sans Pro', sans-serif;
    }

    .policy-modal-container {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
    }

    .policy-modal-container > a {
      color: #7f8fa4;
    }

    .autologin-text-0 {
      font-size: 1.3rem;
    }

    .autologin-text-1 {
      font-size: 1.7rem;
    }

    .login-form {
      display: flex;
      flex-direction: column;
      font-family: 'Source Sans Pro', sans-serif;
    }

    .login-form > a {
      align-self: flex-end;
    }
  }

  .login {
    padding-top: 50px;
    margin-top: 10vh;

    .login__icon--volume {
      font-size: 20px;
      width: 25px;
      height: 25px;
      color: #898989;
      position: relative;
      left: 170px;
      top: -30px;
      cursor: pointer;
    }

    .title {
      position: relative;
      margin: 0 auto;
      width: 400px;
      text-align: center;
      padding: 50px 0px 35px 0px;
      background: #eeeeee;
      margin-top: -40px;
      border-bottom: 1px solid #e7e9ed;

      .logo{
        width: 90px;
      }
    }

    .title h3 {
      font-size: 20px;
      font-weight: 400 !important;
    }

    .content {
      background-color: #fff;
      width: 400px;
      margin: 0px auto 10px;
      padding: 10px 30px 30px;
      overflow: hidden;
      position: relative;
    }

    .content h4 {
      color: #555;
    }

    .content .hint {
      color: #999;
      padding: 0;
      margin: 15px 0 7px;
    }

    .content .forget-form,
    .content .login-form {
      padding: 0;
      margin: 0;
    }

    .content .form-control {
      background-color: #dde3ec;
      height: 43px;
      color: #8290a3;
      border: 1px solid #dde3ec;
    }

    .content .form-control:active,
    .content .form-control:focus {
      border: 1px solid #c3ccda;
    }

    .content .form-control::-moz-placeholder {
      color: #8290a3;
      opacity: 1;
    }

    .content .form-control:-ms-input-placeholder {
      color: #8290a3;
    }

    .content .form-control::-webkit-input-placeholder {
      color: #8290a3;
    }

    .content select.form-control {
      padding-left: 9px;
      padding-right: 9px;
    }

    .content .forget-form,
    .content .register-form {
      /* display:none */
    }

    .content .form-title {
      font-weight: 300;
    }

    .content .form-actions {
      clear: both;
      border: 0;
      border-bottom: 1px solid #eee;
      padding: 25px 30px;
      margin-left: -30px;
      margin-right: -30px;
    }

    .content .form-actions > .btn {
      margin-top: -2px;
    }

    .login-options {
      margin-top: 30px;
      margin-bottom: 30px;
      overflow: hidden;
    }

    .login-options h4 {
      float: left;
      font-weight: 600;
      font-size: 15px;
      color: #7d91aa !important;
    }

    .login-options .social-icons {
      float: right;
      padding-top: 3px;
    }

    .login-options .social-icons li a {
      border-radius: 15px !important;
      -moz-border-radius: 15px !important;
      -webkit-border-radius: 15px !important;
    }

    .content .form-actions .checkbox {
      margin-left: 0;
      padding-left: 0;
    }

    .content .forget-form .form-actions {
      border: 0;
      margin-bottom: 0;
      padding-bottom: 20px;
    }

    .content .register-form .form-actions {
      border: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .content .form-actions .btn {
      margin-top: 1px;
      font-weight: 600;
      padding: 10px 20px !important;
    }

    .content .form-actions .btn-default {
      font-weight: 600;
      padding: 10px 25px !important;
      color: #6c7a8d;
      background-color: #fff;
      border: none;
    }

    .content .form-actions .btn-default:hover {
      background-color: #fafaff;
      color: #45b6af;
    }

    .content .forget-password {
      font-size: 14px;
      float: right;
      display: inline-block;
      margin-top: 10px;
    }

    .content .check {
      color: #8290a3;
    }

    .content .rememberme {
      margin-left: 8px;
    }

    .content .create-account {
      margin: 0 -40px -30px;
      padding: 15px 0 17px;
      text-align: center;
      background-color: #6c7a8d;
      -webkit-border-radius: 0 0 7px 7px;
      -moz-border-radius: 0 0 7px 7px;
      -ms-border-radius: 0 0 7px 7px;
      -o-border-radius: 0 0 7px 7px;
      border-radius: 0 0 7px 7px;
    }

    .content .create-account > p {
      margin: 0;
    }

    .content .create-account p a {
      font-weight: 600;
      font-size: 14px;
      color: #c3cedd;
    }

    .content .create-account a {
      display: inline-block;
      margin-top: 5px;
    }

    .copyright {
      text-align: center;
      margin: 0 auto 30px 0;
      padding: 10px;
      color: #7a8ca5;
      font-size: 13px;
    }

    @media (max-width: 440px) {
      .content {
        margin-top: 10px;
      }

      .content {
        width: 280px;
      }

      .content h3 {
        font-size: 22px;
      }

      .forget-password {
        display: inline-block;
        margin-top: 20px;
      }

      .login-options .social-icons {
        float: left;
        padding-top: 3px;
      }

      .checkbox {
        font-size: 13px;
      }
    }

    form button[type='submit'] {
      background: #2c333d;
      font-size: 20px;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #2c333d;
      padding-left: 0px;
      cursor: pointer;
    }

    form button,
    select {
      height: 46px;
      border: 1px solid #2c333d;
      width: 100%;
      border-radius: 6px;
      background-color: #2c333d;
      padding-left: 60px;
      outline: 0;
      color: #7f8ea3;
      font-size: 15px;
    }

    .login-form .form-group label.control-label {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #7f8fa4;
    }

    .user-settings.btn {
      margin-top: 1rem;
      font-size: 1.3rem;
    }

    .user-settings-container .title {
      width: 50%;
      margin-top: 6rem;
    }

    .user-settings-container .content {
      width: 50%;
    }

    .user-settings-container form button[type='submit'] {
      background: #4a596f;
      border: 1px solid #1e2632;
      color: #2c333d;
    }
  }

  @media (min-width: 700px) {
    .login {
      width: 50vw;
      min-width: 600px;
    }
  }

  .login-settings-container {
    height: 100vh;
    position: relative;
  }
}
