@import '../../style-vars';

$nav-expand-speed: 0.1s;

body.noscroll {
  overflow: hidden;
  padding-right: $nav-scrollbar-width;
}

// mobile view first chakra-ui approach https://chakra-ui.com/docs/styled-system/responsive-styles#:~:text=We%20use%20the%20%40media(min%2Dwidth)
.next-gen-nav {
  .nav-ribbon {
    padding-top: $nav-side-padding;
    background-color: var(--chakra-colors-nav-main);
    position: fixed;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: 100;
    transition: width 0.1s linear;
    padding-left: $nav-side-padding;
    padding-right: $nav-side-padding;
    padding-bottom: 100px;
    font-family: 'Poppins', sans-serif;
    height: calc(100vh - #{$header-height});
    overflow-y: hidden;

    &:hover {
      overflow-y: scroll;
      padding-right: calc(#{$nav-side-padding} - #{$nav-scrollbar-width});
    }

    &::-webkit-scrollbar {
      width: $nav-scrollbar-width;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--chakra-colors-nav-dark);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--chakra-colors-nav-scrollBarBackgroundColor);
      border: 1px solid var(--chakra-colors-nav-dark);
      border-radius: 1em;
    }

    &.open {
      width: $nav-open-width;
      box-shadow: 0 4px 4px var(--chakra-colors-nav-darkShadow);
    }
    &.closed {

      .icon-item-group-level-2 {
        margin-left: 0;
      }
    }

    .nav-group {
      margin-bottom: 10px;
    }

    .nav-group-title {
      white-space: nowrap;
      color: var(--chakra-colors-nav-text);
      transition: padding $nav-expand-speed linear;
      height: 1.2em;
      text-transform: uppercase;
      font-weight: bold;
      padding-left: 8px;
      cursor: pointer;
      display: flex;
      font-family: 'Montserrat', sans-serif;
    }

    .nav-group-collapsed .nav-group-children {
      overflow: hidden;
    }

    .nav-group-children {
      transition: height 0.2s linear;
    }

    &.closed .nav-group-title {
      border-top: 1px solid var(--chakra-colors-nav-text);
    }

    &.open .icon-item {
      width: 100%;
    }

    .icon-item {
      display: flex;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 5px;
      color: var(--chakra-colors-nav-text);
      border-radius: $nav-item-radius;
      width: $nav-icon-button-size;
      height: $nav-icon-button-size;
      overflow: hidden;
      flex-shrink: 0;
      transition: width $nav-expand-speed linear;

      &.icon-item--active {
        background-color: var(--chakra-colors-nav-active);
        box-shadow: -1px 1px 4px var(--chakra-colors-nav-lightShadow);
      }

      &.icon-item:hover {
        background-color: var(--chakra-colors-nav-active);
        box-shadow: -1px 1px 4px var(--chakra-colors-nav-lightShadow);
      }

      &.icon-item-top-level {
        font-size: 15px;
      }

      .icon-item-icon-container {
        width: $nav-icon-button-size;
        height: $nav-icon-button-size;
        font-size: 20px;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .icon-item-level-2 {
      color: transparentize($nav-text, 0.3);
    }
  }

  @media screen and (min-width: 62em) {
    display: block;

    .nav-ribbon {
      height: 100%;

      &.closed {
        display: block;
        width: $nav-closed-width;
      }
    }
  }
}

@media screen and (min-width: 62em) {
  .nav-spacer {
    width: $nav-closed-width;
    flex-shrink: 0;
  }
}
