.food-safety-table {
  margin-bottom: 20px;
  overflow: auto !important;

  .ant-table-thead {
    color: #2d3748;
    font-size: 12px !important;
    font-weight: 600;
    line-height: 1.43;
    text-transform: uppercase;
  }

  // .ant-table-column-sorter {
  //   display: none;
  // }

  .ant-table-cell {
    border-bottom: 1px solid #f0f0f0;
    font-size: 14px;
  }

  .ant-table-thead .ant-table-cell {
    background-color: #f7fafc;
    border-bottom-width: 1px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    content: none;
  }

  .food-safety-table-row,
  .ant-table-column-sort {
    background: #ffffff;
  }

  .food-safety-table-row:hover > td {
    background: #fff !important;
  }
}
